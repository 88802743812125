.cpo-charging-fee-management-page-container{
  width: 100%;
  /* max-width: 1500px; */
  height: fit-content;
  min-height: calc(100vh - 190px);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cpo-charging-fee-management-container p{
  margin: 0;
}
.cpo-charging-fee-management-inner{
  width: 100%;
  max-width: 1400px;
  height: 100%;
  position: relative;
  padding: 20px;

}
.cpo-charging-fee-management-container{
  display: grid;
  position: relative;
  /* border: 1px solid #000; */
  /* box-shadow: 0 0 5px rgba(0,0,0,0.2); */
  /* overflow: hidden; */
}
.cpo-charging-fee-management-header{
  width: 100%;
  height: 50px;
  /* border-bottom: 1px solid #efefef; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 20px;
}

.cpo-charging-fee-management-more-info{
  position: absolute;
  left: 0;
  top: -10px;
  color: var(--inputBorder2);
  display: flex;align-items: center;
}
.cpo-charging-fee-management-more-info>svg{
  cursor: pointer;
  margin-left: 5px;
  font-size: 1.1rem;
}

.cpo-charging-fee-management-title-container{
  display: flex;align-items: center;
}
.cpo-charging-fee-management-title-container title{
  display: inline-block;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}
.cpo-charging-fee-management-title-container>p{
  margin: 0;
  font-size: .9rem;
  color: var(--inputBorder2);
  margin-left: 15px;
}




.cpo-charging-fee-management-rate-header{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.cpo-charging-fee-management-rate-setting-button-wrapper{
  display: flex;
  align-items: center;
}
.cpo-charging-fee-management-rate-initial-button{
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 20px;
  transition: .13s;
  color: var(--inputBorder2);
}
.cpo-charging-fee-management-rate-initial-button:active{
  opacity: 0.3;
}
.cpo-charging-fee-management-rate-setting-button{
  /* margin-bottom: 20px; */
  width: 150px;
  height: 40px;
  background: var(--buttonBlack);
  border-radius: 8px;
  display: flex;align-items: center;justify-content: center;
  color: #fff;
  font-weight: 500; 
  transition: .13s;
  cursor: pointer;
}
.cpo-charging-fee-management-rate-setting-button svg{
  margin-right: 5px;
}
.cpo-charging-fee-management-rate-setting-button:active{
  opacity: 0.3;
}

.cpo-charging-fee-management-event-period-setting-button svg{
  margin-right: 5px;
}
.cpo-charging-fee-management-event-period-setting-button:active{
  opacity: 0.3;
}





.cpo-charging-fee-management-tab-container{
  width: 300px;
  height: 40px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 2px solid var(--tabBorder);
  margin-bottom: 15px;
}

.charging-fee-management-tab-button{
  border-radius: 7px 7px 0 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
}
.charging-fee-management-tab-bar{
  position: absolute;
  width: 50%;
  height: 3px;
  background: var(--emoovBlue);
  top: 37.5px;
  transition: .3s;
  transition: left 0.3s cubic-bezier(0.175, 0.35, 0.5, 1.13);
  z-index: 2;
}
.charging-fee-management-tab-button.selected p{
  color: var(--emoovBlue);
}
.charging-fee-management-tab-button p{
  margin: 0;
  color: var(--oxfordBlue1);
  font-weight: bold;
}
.charging-fee-management-tab-bar.tab0{
  left: 0;
}
.charging-fee-management-tab-bar.tab1{
  left: 50%;
}
.cpo-charging-fee-management-content-header{
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.cpo-charging-fee-management-content-title{
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
}
.cpo-charging-fee-management-content-title color{
  display: inline;
  font-size: .9rem;
  color: var(--inputBorder2);
  font-weight: 400;
  margin: 0;
  margin-left: 15px;
}
.cpo-charging-fee-management-content-title.default color{
  color: var(--textRed1);
}
.cpo-charging-fee-management-input-wrapper{
  display: flex;
  align-items: center;
  width: 380px;
}

.cpo-setting-default-charging-rate-input-save-button{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  min-width: 80px;
  height: 45px;
  text-align: center;
  background: var(--emoovBlue);
  color: #fff;
  margin-top: 15px;
  margin-bottom: 40px;
  margin-left: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: .13s;
}
.cpo-setting-default-charging-rate-input-save-button:active{opacity: 0.3;}
.cpo-setting-default-charging-rate-input-save-button.none{
  pointer-events: none;
  opacity: 0.3;
}



.cpo-charging-fee-management-calendar-container{
  width: 100%;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr 12fr;
}
.cpo-charging-fee-management-calendar-row{
  width: 100%;
  display: grid;
  grid-template-rows: 40px repeat(24,1fr);
  row-gap: 5px;
  position: relative;
}
.cpo-charging-fee-management-calendar-row>div{
  width: 100%;
  height: 30px;
  display: flex;align-items: center;justify-content: left;
  color: var(--emoovBlue);
  font-size: 1rem;
  font-weight: 600;
}
.cpo-charging-fee-management-calendar-column{
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 5px;
  align-items: center;
  height: 40px;
  margin-bottom: 5px;
}

.cpo-charging-fee-management-calendar-column>div{
  width: 100%;
  height: 100%;
  display: flex;align-items: center;justify-content: center;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--emoovBlue);
}







.cpo-charging-fee-management-calendar-table-container{
  display: grid;
  grid-template-columns: repeat(12, 1fr); /* 24개의 열, 각 열의 너비는 30px */
  grid-template-rows: repeat(24, 30px); /* 12개의 행, 각 행의 높이는 30px */
  column-gap: 5px;
  row-gap: 5px;
}

.cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #f9f9f9;
  cursor: pointer;
}
.cell:hover {
  background: #f1f1f1;
}
.cell.high{
  background: var(--emoovLightBlue);
}
.cell.low{
  background: var(--emoovLightBlue2);
}



/* SettingDefaultChargingRateModal */
.cpo-setting-default-charging-rate-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-setting-default-charging-rate-modal-container{
  position: relative;
  width: 950px;
  /* height: 330px; */
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  overflow: hidden;
  padding: 20px;
}
.cpo-station-enroll-modal-header{
  width: 100%;
  margin-bottom: 30px;
}
.cpo-station-enroll-modal-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}
.cpo-setting-default-charging-rate-modal-content-title{
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  display: flex;
  align-items: center;
}

.cpo-setting-default-charging-rate-modal-all-time-checked-button{
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: .13s;
  font-weight: normal;
  color: var(--emoovBlue);
  font-size: 0.9rem;
  margin-left: 20px;
  font-weight: 600;
}
.cpo-setting-default-charging-rate-modal-all-time-checked-button.reset{
  color: var(--buttonBlack);
}
.cpo-setting-default-charging-rate-modal-all-time-checked-button:active{
  opacity: 0.3;
}

.cpo-setting-default-charging-rate-checkbox-grid{
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-bottom: 30px;
}
.cpo-setting-default-charging-rate-checkbox-container{
  display: flex;
  align-items: center;
  width: 100%;
}
.cpo-setting-default-charging-rate-checkbox-container p{
  margin-left: 10px;
  cursor: pointer;
}

.cpo-setting-default-charging-rate-enroll-button{
  width: 100%;
  height: 50px;
  background: var(--emoovBlue);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
  cursor: pointer;
  transition: .17s;
}
.cpo-setting-default-charging-rate-enroll-button:active{
  opacity: 0.3;
}
.cpo-setting-default-charging-rate-enroll-button p{
  margin: 0;
  font-weight: 600;
  font-size: 1.2rem;
}
.cpo-setting-default-charging-rate-enroll-button.none{
  opacity: .3;
  pointer-events: none;
}

.cpo-setting-default-charging-rate-input-container{
  width: 100%;
  height: 45px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder3);
  padding: 0 10px;
  position: relative;
  margin-top: 15px;
  margin-bottom: 40px;
}
.cpo-setting-default-charging-rate-input-container input{
  all: unset;
  height: 100%;
  width: 100%;
}









.cpo-charging-fee-management-table-container{
  margin-bottom: 50px;
}
.cpo-charging-fee-management-table{
  width: 100%;
  height: 192px;
  display: grid;
  grid-template-columns: 0.8fr repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  column-gap: 3px;
  row-gap: 3px;
}
.cpo-charging-fee-management-table>div{
  display: flex; align-items: center; justify-content: center;
}
.cpo-charging-fee-management-table-main-title{
  background: var(--emoovBlue);
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
}
.cpo-charging-fee-management-table-title{
  background: var(--emoovLightBlue2);
  color: var(--emoovBlue);
  font-weight: 600;
  font-size: .9rem;
}
.cpo-charging-fee-management-table-title.column{
  font-size: 1rem;
}

.cpo-charging-fee-management-table-cell{
  background: #F8F9F9;
  padding: 0 10px;
  font-size: 0.9rem;
}
.cpo-charging-fee-management-table-cell input{
  all: unset;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 1.5rem;
}

.cpo-charging-fee-management-function-container{
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 15px;
}
.cpo-charging-fee-management-function-container>p{
  font-size: .9rem;
  color: var(--inputBorder2);
}
.cpo-charging-fee-management-function-button{
  width: 70px;
  height: 45px;
  display: flex;align-items: center;justify-content: center;
  border-radius: 8px;
  margin-left: 10px;
  cursor: pointer;
  transition: .13s;
}
.cpo-charging-fee-management-function-button:active{
  opacity: 0.3;
}
.cpo-charging-fee-management-function-button.cancel{
  border: 1px solid var(--inputBorder3);
  color: var(--inputBorder2);
}
.cpo-charging-fee-management-function-button.save{
  background: var(--emoovBlue);
  color: #fff;
}


.cpo-charging-fee-management-search-container{
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
  position: relative;
}




.cpo-charging-fee-station-table{
  position: relative;
  width: 100%;
  padding-top: 60px;
}
.cpo-charging-fee-station-table title{
  display: inline-block;
  /* font-size: 1.1rem; */
  font-weight: 600;
  margin-bottom: 10px;
  /* margin-top: 10px; */
}



.cpo-charging-fee-station-table-column{
  position: absolute;
  width: 100%;
  height: 60px;
  left: 0px;
  top: 0;
  padding: 0 30px;
  display: grid;
  grid-template-columns: 5fr 2fr 3fr 3fr;
  align-items: center;
  border-top: 2px solid var(--inputBorder2);
  border-bottom: 1px solid var(--inputBorder3);
  color: var(--inputBorder2);
}

.cpo-charging-fee-station-table-column-item{
  display: flex;
  align-items: center;
}
.cpo-charging-fee-station-table-column-item p{
  margin: 0;
  font-size: .9rem;
}


.cpo-charging-fee-station-item-list{
  width: 100%;
  height: 400px;
  padding: 0;
  overflow-y: scroll;
  scrollbar-width: none;
}



.charging-fee-station-item{
  width: 100%;
  height: 60px;
  background: #fff;
  display: grid;
  grid-template-columns: 5fr 2fr 3fr 3fr;
  align-items: center;
  font-size: 0.9em;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 30px;
}















/* ChargingFeeMoreInfo */

.cpo-charging-fee-more-info-modal-container{
  width: 900px;
  height: 150px;
  padding: 0 15px;
  background: #fff;
  box-shadow: 0px 0px 25px 0px #00000026;
  position: absolute;
  left: 0;
  bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.cpo-charging-fee-more-info-modal-container .cpo-charging-fee-management-table{
  width: 100%;
  height: 130px;
  color: #000;
}


.cpo-charging-fee-more-info-modal-container .cpo-charging-fee-management-table .cpo-charging-fee-management-table-main-title{
  background: #D1D1D1;
  font-size: .8rem;
  color: #000;
  font-weight: 600;
}
.cpo-charging-fee-more-info-modal-container .cpo-charging-fee-management-table .cpo-charging-fee-management-table-title{
  background: #D1D1D1;
  font-size: .8rem;
  color: #000;
  font-weight: 00;
}

.cpo-charging-fee-more-info-modal-container .cpo-charging-fee-management-table .cpo-charging-fee-management-table-cell{
  font-size: .75rem;
}




.cpo-charger-management-station-selector-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cpo-charger-management-station-selector-wrapper>.selector-container{
  min-width: 500px;
}
.cpo-charging-fee-management-event-setting-button{
  width: 150px;
  height: 40px;
  background: var(--buttonBlack);
  border-radius: 8px;
  display: flex;align-items: center;justify-content: center;
  color: #fff;
  font-weight: 500; 
  transition: .13s;
  cursor: pointer;
}
.cpo-charging-fee-management-event-setting-button svg{
  margin-right: 5px;
}
.cpo-charging-fee-management-event-setting-button:active{
  opacity: 0.3;
}





























/* ChargingFeeSearchModal */
.cpo-charging-fee-search-modal-container{
  position: absolute;
  bottom: -310px;
  width: 700px;
  height: 300px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px #00000040;
  z-index: 10;
  padding: 30px;
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  row-gap: 10px;
}
.cpo-charging-fee-search-modal-container>div{
  width: 100%;
}
.cpo-charging-fee-search-modal-row{
  display: grid;
  grid-template-columns: 1fr 3fr;
}
.cpo-charging-fee-search-modal-row.divide{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
}
.cpo-charging-fee-search-modal-row.divide>div{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.cpo-charging-fee-search-modal-button-container{
  display: flex;align-items: center;justify-content: end;
}
.cpo-charging-fee-search-modal-button{
  display: flex;align-items: center;justify-content: center;
  border-radius: 8px;
  height: 45px;
  background: var(--emoovBlue);
  padding: 0 25px;
  color: #fff;
  cursor: pointer;
  transition: .13s;
}
.cpo-charging-fee-search-modal-button:active{
  opacity: 0.3;
}





/*  */
.charging-fee-modal-charger-item{
  width: 100%;
  height: 80px;
  border-radius: 8px;
  background: #fff;
  margin: 20px 0;
  box-shadow: 0px 3px 15px 0px #00000014;
  display: grid;
  grid-template-columns: 1.1fr 5fr 5fr;
  align-items: center;
  padding: 0 20px;
  font-size: 0.9em;
}

.charging-fee-status-badge{
  border-radius: 100vh;
  border: 2px solid var(--emoovBlue);
  width: fit-content;
  height: 30px;
  padding: 0 10px;
  display: flex;align-items: center;justify-content: center;
  font-size: .8rem;
  color: var(--emoovBlue);
  font-weight: 500;
}
.cpo-charging-fee-management-event-content-container{
  width: 100%;
  min-height: 300px;
  margin-top: 30px;
}


.cpo-charging-fee-management-event-content-item{
  width: 100%;
  height: 50px;
  border-radius: 8px;
  background: #f7f7f7;
  margin-bottom: 10px;
  display: flex;align-items: center;
  
  padding: 0 15px;
  
}
.cpo-charging-fee-management-event-content-item>div{
  margin-right: 10px;
}