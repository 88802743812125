.billing-key-page-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.billing-key-title{
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}
.billing-form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.billing-label {
  font-weight: bold;
  font-size: 14px;
  color: #333;
  margin-bottom: 4px;
  display: block;
}

.billing-input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 6px;
  transition: all 0.2s;
}

.billing-input:focus {
  border-color: #007aff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 122, 255, 0.5);
}

.billing-button {
  width: 100%;
  padding: 12px;
  background: #007aff;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s;
  margin-top: 20px;
}

.billing-button:hover {
  background: #005ecb;
}

/* 📱 모바일 반응형 스타일 */
@media screen and (max-width: 480px) {
  .billing-key-page-container {
    width: 90%;
    padding: 15px;
  }

  .billing-input {
    padding: 8px;
    font-size: 13px;
  }

  .billing-button {
    padding: 10px;
    font-size: 15px;
  }
}
