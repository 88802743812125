.cpo-charger-model-management-container{
  width: 100%;
  /* max-width: 1500px; */
  height: fit-content;
  min-height: max(calc(100vh - 190px), 900px) ;
  /* border: 1px solid #000; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cpo-charger-model-management-inner{
  width: 100%;
  max-width: 1400px;
  min-width: 1400px;
  height: 100%;
  position: relative;

}


.cpo-charger-model-management-content-container{
  width: 100%;
}
.cpo-charger-model-management-content-container.grid{
  display: grid;
  grid-template-columns: 1fr 500px;
}

.cpo-charger-model-management-content-header{
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cpo-charger-model-management-content-search-container{
  display: flex;
  align-items: center;
}


.cpo-charger-model-management-content-search-filter-button{
  width: fit-content;
  height: 45px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder3);
  color: var(--inputBorder2);
  font-weight: 500;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .13s;
}

.cpo-charger-model-management-content-search-filter-button:active{
  opacity: 0.3;
}
.cpo-charger-model-management-content-search-filter-button>svg{
  margin-left: 10px;
  font-size: 1.2rem;
}

.cpo-charger-model-management-content-search-input-container{
  display: grid;
  grid-template-columns: 1fr 20px;
  column-gap: 5px;
  align-items: center;
  height: 30px;
  width: 300px;
  border-bottom: 2px solid var(--inputBorder2);
  position: relative;
  margin-left: 30px;
  padding: 0 5px 5px 5px;
  transition: .3s;
}
.cpo-charger-model-management-content-search-input-container:has(input:focus){
  border-bottom: 2px solid var(--emoovBlue);
  
}
.cpo-charger-model-management-content-search-input-container input{
  all: unset;
  width: 100%;
  height: 100%;
}
.cpo-charger-model-management-content-search-input-container>svg{
  color: var(--inputBorder2);
  cursor: pointer;
  transition: .13s;
}
.cpo-charger-model-management-content-search-input-container>svg:active{
  opacity: 0.3;
}

.cpo-charger-model-management-content-function-button-container{
  display: flex;
  align-items: center;
}
.cpo-charger-model-function-info-msg{
  font-size: 1rem;
  font-weight: 500;
  color: var(--emoovBlue);
  margin-right: 15px;
}
.cpo-charger-model-function-info-msg.delete{
  color: var(--textRed1);
}
.cpo-charger-model-management-content-function-button{
  height: 45px;
  padding: 0 15px;
  display: flex;align-items: center;justify-content: center;
  border-radius: 8px;
  margin-left: 10px;
  cursor: pointer;
  transition: .13s;
}
.cpo-charger-model-management-content-function-button:active{
  opacity: 0.3;
}
.cpo-charger-model-management-content-function-button.enroll{
  background: var(--emoovBlue);
  color: #fff;
}
.cpo-charger-model-management-content-function-button.edit{
  border: 1px solid var(--emoovBlue);
  color: var(--emoovBlue);
  position: relative;
}
.cpo-charger-model-management-content-function-button.delete{
  border: 1px solid var(--textRed1);
  color: var(--textRed1);
  position: relative;
}
.cpo-charger-model-management-content-function-button.cancel{
  border: 1px solid var(--inputBorder3);
  color: var(--inputBorder2);
}
.cpo-charger-model-management-content-function-button.disabled{
  opacity: 0.3;
  pointer-events: none;
}


.cpo-charger-model-management-content-item-list-container{
  width: 100%;
  min-height: 300px;
  border-top: 2px solid var(--inputBorder2);
}
.cpo-charger-model-management-content-item-column{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 1.8fr 1fr 1.3fr 1fr 0.5fr;
  column-gap: 10px;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;

  font-size: .9rem;
  color: var(--inputBorder2);
}
.cpo-charger-model-management-content-item-column.tab0{
  grid-template-columns: 2.5fr 2fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 1.25fr 1.25fr 1fr 1fr;

}
.cpo-charger-model-management-content-item-column.tab1{
  grid-template-columns: 0.5fr 2.5fr 2fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 1.25fr 1.25fr 1fr 1fr;
}






/* CpoChargerModelListItem */

.cpo-charger-model-management-content-item{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 2.5fr 2fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 1.25fr 1.25fr 1fr 1fr;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  column-gap: 10px;
  font-size: .9rem;
  cursor: pointer;
}
.cpo-charger-model-management-content-item.checkMode{
  grid-template-columns: 0.5fr 2.5fr 2fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 1.25fr 1.25fr 1fr 1fr;
}
.cpo-charger-model-management-content-item.checked{
  background: var(--emoovLightBlue2);
}

.cpo-charger-model-management-content-item p{
  margin: 0;
}

.cpo-charger-model-management-content-item>div{
  display: flex;align-items: center;
}
.cpo-charger-model-management-content-item>div>svg{
  margin-left: 5px;
  transition: .13s;
}

.cpo-charger-model-management-content-item-content{
  background: #F7F7F7;
  visibility: hidden;
  max-height: 0;
  /* padding: 0 20px; */
}
.cpo-charger-model-management-content-item-content>div{
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 15px;
}
.cpo-charger-model-management-content-item-content>div:last-child{
  margin-bottom: 15px;
}
.cpo-charger-model-management-content-item-content>div>p{
  margin: 0;
  margin-right: 10px;
  color: var(--emoovBlue);
  font-weight: 500;
}

.cpo-charger-model-management-content-item-container.open .cpo-charger-model-management-content-item-content{
  visibility: visible;
  max-height: none;
  border-bottom: 1px solid var(--inputBorder3);
  padding-top: 15px;
}
.cpo-charger-model-management-content-item-container.open .cpo-charger-model-management-content-item>div>svg{
  transform: rotate(-180deg);
}

.cpo-charger-model-connector-badge{
  display: flex;align-items: center;justify-content: center;
  width: fit-content;
  height: 30px;
  border: 2px solid var(--emoovBlue);
  border-radius: 100vh;
  font-weight: 500;
  padding: 0 5px;
  margin-right: 5px;
  background: #fff;
}
.cpo-charger-model-connector-badge>p{
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
}


.cpo-charger-model-management-content-item-content>div.cpo-charger-model-management-connector-list-container{
  display: block;
  padding: 0;
  width: 100%;
}

.cpo-charger-model-management-connector-list-container{
  width: 100%;
}
.cpo-charger-model-management-connector-list-item-column{
  width: calc(100% - 30px);
  height: 50px;
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  margin: 0 15px;
  column-gap: 10px;
  font-size: .9rem;
}
.cpo-charger-model-management-connector-list-item-column>div:last-child{
  text-align: right;
}
.cpo-charger-model-management-connector-list-item-container{
  width: 100%;
  overflow-y: overlay;
  scrollbar-gutter: stable;
  padding-left: 15px;
}

.cpo-charger-model-management-connector-list-item{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  column-gap: 10px;
  font-size: .9rem;

}
.cpo-charger-model-management-connector-list-item>div{
  display: flex;
  align-items: center;
}














/* CpoChargerModelEnrollModal */
.cpo-charger-model-enroll-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-charger-model-enroll-modal-container{
  position: relative;
  max-width: 1100px;
  width: fit-content;
  height: fit-content;
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  /* overflow: hidden; */
  padding: 30px 30px;
  z-index: 1000;
}
.cpo-charger-model-enroll-modal-header{
  width: 100%;
  margin-bottom: 30px;
  display: flex; align-items: center;
}
.cpo-charger-model-enroll-modal-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}
.cpo-charger-model-enroll-modal-close-button{
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: .13s;
}
.cpo-payment-function-modal-close-button:active{
  opacity: 0.3;
}


.cpo-charger-model-enroll-modal-content{
  width: 1000px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 60px;
}
.cpo-charger-model-enroll-modal-content>div{
  margin: 20px 0;
}
.cpo-charger-model-enroll-modal-content-header{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.cpo-charger-model-enroll-modal-content-title{
  color: var(--emoovBlue);
  font-weight: 600;
  margin: 0;
  font-size: 1.2rem;
}
.cpo-charger-model-enroll-modal-content-desc{
  font-size: .9rem;
  margin-left: 5px;
  color: var(--textGray3);
}

.cpo-charger-model-enroll-modal-content-wrapper{
  width: 100%;
  margin-bottom: 15px;
}
.cpo-charger-model-enroll-modal-content-wrapper.marginTop{
  margin-top: 40px;
}
.cpo-charger-model-enroll-modal-content-wrapper.divide{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
}
.cpo-charger-model-enroll-modal-content-wrapper.divide-no-margin{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.cpo-charger-model-enroll-modal-content-wrapper.triple{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 30px;
}
.cpo-charger-model-enroll-modal-content-wrapper.triple.date{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 30px;
}
.cpo-charger-model-enroll-modal-content-wrapper.triple.date>div{
  grid-column: 1/3;
}

.cpo-charger-model-enroll-modal-content-wrapper>p{
  font-weight: 500;
  margin: 0;
  margin-bottom: 15px;
}
.cpo-charger-model-enroll-modal-content-input-container{
  width: 100%;
  height: 45px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder3);
  padding: 0 10px;
  position: relative;
}
.cpo-charger-model-enroll-modal-content-input-container.postFix{
  display: grid;
  grid-template-columns: 1fr 40px;
  align-items: center;
}
.cpo-charger-model-enroll-modal-content-input-container.postFix>p{
  text-align: right;
  font-size: .8rem;
}
.cpo-charger-model-enroll-modal-content-input-container input{
  all: unset;
  height: 100%;
  width: 100%;
}

.cpo-charger-model-enroll-modal-content-textarea-container{
  width: 100%;
  height: 150px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder3);
  padding: 10px;
}

.cpo-charger-model-enroll-modal-content-textarea-container textarea{
  all: unset;
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 1.5rem;
  font-size: 1rem;
}
.cpo-charger-model-enroll-modal-content-selector-container{
  width: 100%;
  border-radius: 8px;
  height: 45px;
}
.cpo-charger-model-enroll-modal-content-selector-container .selector{

  border: 1px solid var(--inputBorder3);
  
}


.cpo-charger-model-enroll-modal-checkbox-container{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 15px;
}
.cpo-charger-model-enroll-modal-checkbox-container>div{
  display: flex;align-items: center;
  margin-bottom: 15px;
}
.cpo-charger-model-enroll-modal-checkbox-container>div>p{
  margin: 0;
  margin-left: 10px;
}


.cpo-charger-model-enroll-modal-radio-container{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 15px;
}
.cpo-charger-model-enroll-modal-radio-container>div{
  margin-bottom: 15px;
}








.cpo-charger-model-enroll-modal-button-container{
  display: flex;
  align-items: center;
  justify-content: end;
}
.cpo-charger-model-enroll-modal-button{
  height: 45px;
  padding: 0 15px;
  border-radius: 8px;
  background: #3d3d3d;
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;justify-content: center;
  cursor: pointer;
  transition: .13s;
  position: relative;
}
.cpo-charger-model-enroll-modal-button:active{
  opacity: 0.3;
}
.cpo-charger-model-enroll-modal-button.none{
  pointer-events: none;
  opacity: 0.3;
}


.cpo-charger-model-content-title{
  position: relative;
  display: inline-block;
  width: fit-content;
}
.cpo-charger-model-content-title.required::after{
  content: "*";
  position: absolute;
  left: calc(100% + 5px);
  font-size: 1rem;
  color: var(--textRed1);
}

.error-msg{
  position: absolute;
  bottom: 25px;right: 130px;
  color: var(--textRed1);
}







/* ************************************************************ */
/* *ChargerModelDeleteModal */
/* ************************************************************ */

.charger-model-delete-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.charger-model-delete-modal-container{
  position: relative;
  min-width: 500px;
  min-height: 330px;
  height: fit-content;
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  /* overflow: hidden; */
  padding: 20px;
  z-index: 1000;
  display: grid;
  grid-template-rows: auto 1fr 50px;
}
.charger-model-delete-modal-header{
  width: 100%;
  margin-bottom: 30px;
}
.charger-model-delete-modal-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}
.charger-model-delete-modal-list-wrapper{
  max-height: 300px;
  overflow-y: scroll;
}
.charger-model-delete-modal-msg{
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
}
.charger-model-delete-modal-item{
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 15px 0;
}

.charger-model-delete-modal-container>div:last-child{
  display: flex;align-items: center;
  justify-content: space-between;
  
}
.charger-model-delete-modal-delete-button{
  width: 	100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
  transition: .13s;
  background: var(--cardBtnRed);
  cursor: pointer;
}
.charger-model-delete-modal-delete-button:active{
  opacity: 0.3;
}
.charger-model-delete-modal-delete-button p{
  margin: 0;
  font-weight: 600;
  font-size: 1rem;
  color: var(--textRed1);
}

.charger-model-delete-modal-delete-button.close{
  background: var(--buttonBlack);
  color: #fff;
}
.charger-model-delete-modal-delete-button.close>p{
  color: #fff;
}







/* view-mode-button */
.cpo-charger-management-view-mode-button-container{
  display: flex;
  align-items: center;
}
.cpo-charger-management-view-mode-button{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: var(--inputBorder2);
  cursor: pointer;
  transition: .13s;
  margin-right: 5px;
}
.cpo-charger-management-view-mode-button:active{
  opacity: 0.3;
}
.cpo-charger-management-view-mode-button.clicked{
  color: var(--emoovBlue);
}