/* Station */
.sub-cpo-station-management-container{
  width: 100%;
  /* max-width: 1500px; */
  height: fit-content;
  min-height: calc(100vh - 190px);
  /* border: 1px solid #000; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
}
.sub-cpo-station-management-inner{
  width: 100%;
  /* margin: 0 50px; */
  min-width: 1750px;
  max-width: 1750px;
  height: 100%;
  position: relative;

  display: grid;
  grid-template-columns: 250px 1fr;
  column-gap: 15px;
  /* background: #fafafa; */
  padding: 15px;
}
.sub-cpo-station-management-inner>div{
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  border-radius: 8px;
  background: #fff;
  position: relative;
  overflow: hidden;
}
.sub-cpo-station-management-left-container{
  background: #fff;
}

.sub-cpo-station-management-header{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  /* border-bottom: 3px solid var(--emoovBlue);
  border-top: 3px solid var(--emoovBlue); */
  background: var(--emoovBlue);
  color: #fff;
  font-weight: 600;
}
.sub-cpo-station-management-left-list{
  width: 100%;
  /* padding: 0 20px; */
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sub-cpo-station-management-sub-cpo-item{
  width: 100%;
  height: 60px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  margin: 15px 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: .3s;
  padding-left: 20px;
  display: flex;align-items: center;
}
.sub-cpo-station-management-sub-cpo-item>p{
  margin: 0;
  font-size: .9rem;
  font-weight: 500;
}
.sub-cpo-station-management-sub-cpo-item.selected{
  /* transform: translateX(-20px); */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px, rgba(0, 0, 0, 0.16) 0px 1px 10px, 0 0 0 3px var(--emoovBlue);
}
.sub-cpo-station-management-sub-cpo-item.selected>p{
  color: var(--emoovBlue);
  font-weight: bold;
}








.sub-cpo-station-management-right-container{
  background: #fff;
  width: 100%;
  min-height: 600px;
  padding: 0 20px;
  padding-bottom: 20px;
}
.sub-cpo-station-management-right-container.none{
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub-cpo-station-management-before-select{
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--inputBorder);
  background: #efefef;
  border-radius: 8px;
}
.sub-cpo-station-management-before-select svg{
  font-size: 4.5rem;
  margin-bottom: 15px;
}
.sub-cpo-station-management-before-select>div>svg:first-child{
  color: var(--inputBorder3);
  transform: translateX(50%);
}
.sub-cpo-station-management-before-select>div>svg:last-child{
  color: var(--emoovBrightBlue);
  font-size: 4.5rem;
  transform: translate(-25px,-10px);
}