.find-password-page-container{
  min-width: 900px;
  min-height: 600px;
  background: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  margin-top: 30px;
  margin-bottom: 80px;
}
.find-password-page-container.label{
  position: relative;
}
.find-password-page-wrapper{
  /* width: 450px; */
  height: fit-content;
  margin-top: 80px;
}
.find-password-page-wrapper title{
  display: inline-block;
  width: 100%;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
}

.find-password-tab-container{
  margin-top: 100px;
  min-height: 600px;
  margin-bottom: 30px;
}
.find-password-tab-container.done{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
}

.find-password-tab-title{
  margin: 0;
  font-size: 1.2rem;
  font-weight: 700;
}


.find-password-input-row{
  width: max-content;
  min-width: 700px;
  display: grid;
  grid-template-columns: 130px 1fr auto;
  margin-top: 15px;
  margin-bottom: 40px;
  position: relative;
}
.find-password-input-title{
  margin: 0;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: .9rem;
  font-weight: 600;
}
.find-password-input-container{
  width: 100%;
  height: 55px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder);
  /* padding: 0 20px; */
  transition: .13s;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.find-password-input-container:has(input:focus){
  border: 1px solid var(--emoovBlue);
}
.find-password-input-container:has(input:read-only:focus){
  border: 1px solid var(--inputBorder);
}
.find-password-input-container.invalid{
  border: 1px solid rgb(255, 82, 82);
}
.find-password-input-container input{
  all: unset;
  width: calc(100% - 40px);
  padding: 0 20px;
  height: 100%;
  pointer-events: text;
  /* background: #000; */
}
.find-password-input-container input:read-only{
  background: var(--inputBorder3);
  color: var(--inputBorder2);
}

.find-password-input-container.password input{
  padding-right: 40px;
}
.find-password-input-container input::placeholder{
  color: var(--placeholderGray);
}
.find-password-input-container svg{
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  color: var(--textGray);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.find-password-input-container path{
  display: block;
  height: 24px;
}




.find-password-token-send-button{
  width: 110px;
  height: 55px;
  border-radius: 8px;
  background: var(--emoovBlue);
  color: #fff;
  margin-left: 20px;
  display: flex;
  align-items: center;justify-content: center;
  font-weight: 600;
  cursor: pointer;
  transition: .13s;
}
.find-password-token-send-button.none{
  opacity: 0.3;
  pointer-events: none;
}
.find-password-token-send-button:active{
  opacity: 0.3;
}
.find-password-validation-msg{
  margin: 0;
  position: absolute;
  bottom: -1.2rem;
  left: 150px;
  font-size: .8rem;
  color: var(--textGray);
}
.find-password-validation-msg.alert{
  color: rgb(255, 82, 82)
}
.find-password-validation-msg.able{
  color: var(--emoovBlue);
}

.find-password-go-to-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 100px;
  position: relative;
}
.find-password-go-to-container>p{
  position: absolute;
  right: 0px;
  top: -30px;
  margin: 0;
  color: var(--textRed1);
}
.find-password-go-to-button{
  width: 200px;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .13s;
}
.find-password-go-to-button:active{
  opacity: 0.3;
}
.find-password-go-to-button p{
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
  color: #fff;
}
.find-password-go-to-button.back{
  background: #B5B5B5;
}
.find-password-go-to-button.request{
  background: var(--emoovBlue);
}
.find-password-go-to-button.disabled{
  opacity: 0.3;
  pointer-events: none;
}

.find-password-done-msg{
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 50px;
}
.find-password-done-button{
  width: fit-content;
  height: 55px;
  background: var(--emoovBlue);
  color: #fff;
  font-weight: 600;
  display: flex;
  align-items: center;justify-content: center;
  cursor: pointer;
  transition: .13s;
  border-radius: 8px;
  padding: 0 20px;
}
.find-password-done-button:active{
  opacity: 0.3;
}