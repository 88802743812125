.cpo-income-report-container{
  width: 100%;
  /* max-width: 1500px; */
  height: fit-content;
  min-height: max(calc(100vh - 190px), 900px) ;
  /* border: 1px solid #000; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cpo-income-report-inner{
  width: 100%;
  max-width: 1400px;
  min-width: 1400px;
  height: 100%;
  position: relative;

}


.cpo-income-report-content-container{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
}

.cpo-income-report-content-container>div{
  width: 100%;
  height: fit-content;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
}

.cpo-income-report-summary-container{
  width: 100%;
  height: 80px;
  /* background: var(--emoovBlue10); */
  background: #fefefe;
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 0 20px;
  box-shadow: 0px 0px 0px 3px var(--emoovBlue10);
  
}

.cpo-income-report-summary-item{
  margin-right: 40px;
  position: relative;

}

.cpo-income-report-summary-item-title{
  font-size: 1rem;
  margin-bottom: 5px;
}
.cpo-income-report-summary-item-title.success{
  color: var(--emoovBlue);
  font-weight: 600;
}
.cpo-income-report-summary-item-title.failure{
  color: var(--textRed1);
  font-weight: 600;
}
.cpo-income-report-summary-item-content{
  font-size: 1.4rem;
  font-weight: 600;
  position: relative;
}
.cpo-income-report-summary-item::after{
  content:"";
  position: absolute;
  right: -19px;
  top: 0;
  background: var(--inputBorder3);
  width: 1px;
  height: 100%;
}

.cpo-income-report-summary-item-content::after{
  content: "건";
  font-weight: 400;
  bottom: 0;
  right: 0;
  font-size: 1rem;
  margin-left: 5px;
}
.cpo-income-report-summary-item-content.revenue::after{
  content: "원";
}
.cpo-income-report-summary-item-content.energyCharged::after{
  content: "kWh";
}
.cpo-income-report-summary-item-content.chargingTime::after{
  content: "분";
}


.cpo-income-report-period-container{
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: var(--emoovBlue);
  font-weight: 600;
  margin-left: 30px;
}







/**********************************************************/
/** CpoIncomeReportSearchModal  */
/**********************************************************/



.cpo-income-report-search-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-income-report-search-modal-container{
  position: relative;
  max-width: 1100px;
  width: fit-content;
  height: fit-content;
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  /* overflow: hidden; */
  padding: 30px 30px;
  z-index: 1000;
}
.cpo-income-report-search-modal-header{
  width: 100%;
  margin-bottom: 30px;
  display: flex; align-items: center;
}
.cpo-income-report-search-modal-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}
.cpo-income-report-search-modal-header p{
  margin: 0;
  color: var(--inputBorder2);
}
.cpo-income-report-search-modal-close-button{
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: .13s;
}
.cpo-income-report-search-modal-close-button:active{
  opacity: 0.3;
}

.cpo-income-report-search-modal-content-section{
  width: 600px;
}

.cpo-income-report-search-modal-button-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  height: 45px;
}
.cpo-income-report-search-modal-button{
  width: fit-content;
  padding: 0 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  border-radius: 8px;
  background: var(--buttonBlack);
  cursor: pointer;
  transition: .13s;
}
.cpo-income-report-search-modal-button.reset{
  border: 1px solid var(--inputBorder2);
  color: var(--inputBorder2);
  background: #fff;
  padding: 0 10px;
  margin-right: 15px;
}
.cpo-income-report-search-modal-button:active{
  opacity: 0.3;
}




.cpo-income-report-modal-content-wrapper{
  width: 100%;
  margin-bottom: 15px;
  position: relative;
}
.cpo-income-report-modal-content-wrapper.marginTop{
  margin-top: 50px;
}
.cpo-income-report-modal-content-wrapper.divide{
  display: grid;
  grid-template-columns: 1fr 60px 1fr;
  width: 100%;
  align-items: center;
}

.cpo-income-report-modal-content-wrapper>div:nth-child(2){
  display: flex;
  align-items: center;
  justify-content: center;
}

.cpo-income-report-modal-content-wrapper.subTitle{
  display: flex;
  align-items: center;
}

.cpo-income-report-modal-content-wrapper.subTitle>p:last-child{
  margin-left: 10px;
  font-size: .9rem;
  color: var(--inputBorder);
}
.cpo-income-report-modal-content-wrapper>p{
  font-weight: 500;
  margin: 0;
  margin-bottom: 15px;
}
.cpo-income-report-modal-content-input-container{
  width: 100%;
  height: 45px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder3);
  padding: 0 10px;
  position: relative;
}
.cpo-income-report-modal-content-input-container input{
  all: unset;
  height: 100%;
  width: 100%;
}
