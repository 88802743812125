.cpo-dashboard-container{
  width: 100%;
  height: fit-content;
  min-height: max(calc(100vh - 190px), 900px) ;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cpo-dashboard-inner{
  width: 100%;
  max-width: 1150px;
  min-width: 1150px;
  height: 100%;
  position: relative;
  margin-top: 40px;
}

.cpo-dashboard-charger-info-grid{
  width: 100%;
  height: 150px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px #D1E4E9;
  margin-bottom: 50px;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
}
.cpo-dashboard-charger-info-all-charger{
  display: grid;
  grid-template-columns: 70px 1fr;
  column-gap: 15px;
  height: 100%;
  border-radius: 8px;
  width: 250px;
  margin-top: 35px;
}
.cpo-dashboard-charger-info-all-charger:first-child{
  margin-right: 25px;
}
.cpo-dashboard-charger-info-all-charger-title{
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 15px;
}
.cpo-dashboard-charger-info-all-charger-content{
  display: flex;
  align-items: center;
}
.cpo-dashboard-charger-info-all-charger-content p{
  margin: 0;
}
.cpo-dashboard-charger-info-all-charger-content p:first-child{
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 5px;
}
.cpo-dashboard-charger-info-category-wrapper{
  display: flex;
  align-items: center;
  height: 100%;
  margin-top: 10px;
}
.cpo-dashboard-charger-info-category-wrapper>div{
  height: 100%;
  padding: 10px;
  min-width: 130px;
}

.cpo-dashboard-charger-info-category-title{
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  position: relative;
}
.cpo-dashboard-charger-info-category-title::before{
  content: "";
  display: block;
  position: absolute;
  left: -15px;
  border-radius: 100vh;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background: #00B2FF;
}
.cpo-dashboard-charger-info-category-title.able::before{
  background: #00B2FF;
}
.cpo-dashboard-charger-info-category-title.charging::before{
  background: #43E65B;
}
.cpo-dashboard-charger-info-category-title.disable::before{
  background: #FF4382;
}
.cpo-dashboard-charger-info-category-content{
  display: flex;
  align-items: end;
}
.cpo-dashboard-charger-info-category-content>p{
  margin: 0;
}
.cpo-dashboard-charger-info-category-content>p:first-child{
  font-size: 1.5rem;
  margin-right: 5px;
  font-weight: bold;
}





.cpo-dashboard-pay-report-info-grid{
  width: 100%;
  height: 400px;
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  column-gap: 50px;
  margin-bottom: 50px;
}

.cpo-dashboard-pay-report-info-tody-container{
  display: grid;
  grid-template-rows: 45px 1fr;
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: 0px 0px 20px 0px #D1E4E9;
  border-radius: 8px;
}
.cpo-dashboard-pay-report-info-tody-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}
.cpo-dashboard-pay-report-info-tody-header>p{
  margin: 0;
  font-weight: 500;
}
.cpo-dashboard-pay-report-info-tody-content-section{
  width: 100%;
  height: 100%;
  padding: 15px;
}
.cpo-dashboard-pay-report-info-tody-inner{
  width: 100%;height: 100%;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0px 0px 20px 0px #D1E4E9;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  row-gap: 15px;
}
.cpo-dashboard-pay-report-info-tody-inner>div{

}
.cpo-dashboard-pay-report-info-tody-title{
  font-size: .9rem;
  margin: 0;
  margin-bottom: 10px;
}
.cpo-dashboard-pay-report-info-tody-content{
  display: grid;
  grid-template-columns: 55px 1fr;
  column-gap: 15px;
}
.cpo-dashboard-pay-report-info-tody-content-image{
  width: 55px;
  height: 55px;
  border-radius: 8px;
  /* background: #CAF8FF; */
}
.cpo-dashboard-pay-report-info-tody-content-image>img{
  width: 100%;
  object-position: 50% 50%;
  object-fit:cover;
  color: #000;
  margin-right: 30px;
}
.rectCoin{
  content: var(--rectCoin);
}
.rectBattery{
  content: var(--rectBattery);
}
.rectOutlet{
  content: var(--rectOutlet);
}




.cpo-dashboard-pay-report-info-tody-content>div:last-child{
  display: flex;align-items: end;
  margin-bottom: 15px;
  width: 100%;
}
.cpo-dashboard-pay-report-info-tody-content>div:last-child>p{
  margin: 0;
}
.cpo-dashboard-pay-report-info-tody-content>div:last-child>p:first-child{
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 5px;
}

.cpo-dashboard-pay-report-info-category-container{
  display: grid;
  grid-template-rows: 45px 1fr;
  width: 100%;
  height: 100%;
}
.cpo-dashboard-pay-report-info-category-header{
  display: flex;
  align-items: center;
}
.cpo-dashboard-pay-report-info-category-button{
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -2px 2px 0px #D1E4E9;
  border-radius: 8px 8px 0 0;
  width: 100px;
  height: 100%;
  margin-right: 10px;
  background: #f7f7f7;
  font-weight: 500;
  color: var(--inputBorder);
  cursor: pointer;
  transition: .13s;
}
.cpo-dashboard-pay-report-info-category-button:active{
  opacity: 0.3;
}
.cpo-dashboard-pay-report-info-category-button.clicked{
  z-index: 5;
  background: #fff;
  color: #000;
  pointer-events: none;
}
.cpo-dashboard-pay-report-info-category-content-section{
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 20px 0px #D1E4E9;
  border-radius: 0 8px 8px 8px;
  background: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 15px;
  row-gap: 15px;
  padding: 15px;
}
.cpo-dashboard-pay-report-info-category-content-container{
  box-shadow: 0px 0px 20px 0px #D1E4E9;
  background: #fff;
  border-radius: 8px;
  display: grid;
  grid-template-rows: 35px 1fr;
}
.cpo-dashboard-pay-report-info-category-content-container.top{
  grid-column: -1/1;
}

.cpo-dashboard-pay-report-info-category-content-title{
  display: flex;align-items: center;
  margin-left: 15px;
  font-size: 0.9rem;
  font-weight: 500;
}
.cpo-dashboard-pay-report-info-category-content-title>p:first-child{
  margin-right: 10px;
}

.cpo-dashboard-pay-report-info-category-content{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: end;justify-content: center;
  padding-bottom: 50px;
}
.cpo-dashboard-pay-report-info-category-content>p:first-child{
  font-size: 2rem;
  margin: 0;
  font-weight: 600;
}
.cpo-dashboard-pay-report-info-category-content>p:last-child{
  margin: 0;
  margin-left: 5px;
  color: var(--inputBorder2);
}

.cpo-dashboard-pay-report-info-category-content-container .top{
  background-image: var(--coinsBlueTrans);
  background-position: 100% 100%;
  background-size: 100%;
  /* object-fit:cover; */
}



.cpo-dashboard-pay-report-graph-grid{
  display: grid;
  grid-template-rows: 45px 1fr;
  width: 100%;
  height: 500px;
  margin-bottom: 50px;
  position: relative;
}
.cpo-dashboard-pay-report-graph-grid-header{
  display: flex;
  align-items: center;
}
.cpo-dashboard-pay-report-graph-grid-button{
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -2px 2px 0px #D1E4E9;
  border-radius: 8px 8px 0 0;
  width: fit-content;
  padding: 0 15px;
  height: 100%;
  margin-right: 10px;
  background: #f7f7f7;
  font-weight: 500;
  color: var(--inputBorder);
  cursor: pointer;
  transition: .13s;
}
.cpo-dashboard-pay-report-graph-grid-button:active{
  opacity: 0.3;
}
.cpo-dashboard-pay-report-graph-grid-button.clicked{
  z-index: 5;
  background: #fff;
  color: #000;
  pointer-events: none;
}
.cpo-dashboard-pay-report-graph-grid-content-section{
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 20px 0px #D1E4E9;
  border-radius: 0 8px 8px 8px;
  background: #fff;
  position: relative;
  display: grid;
  grid-template-rows: 40px 1fr;
}
.cpo-dashboard-pay-report-graph-grid-content-section>div:last-child{
  width: 100%;
  height: 98%;
}



.cpo-dashboard-average-report-grid{
  width: 100%;
  height: 400px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px #D1E4E9;
  margin-bottom: 50px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 55px 1fr;
  column-gap: 15px;
  padding: 15px;
}
.cpo-dashboard-average-report-grid-header{
  grid-column: -1/1;
  display: flex;align-self: center;justify-content: space-between;
  width: 100%;
  height: 100%;
}
.cpo-dashboard-average-report-grid-header>p{
  margin: 0;
  font-weight: 500;
}
.cpo-dashboard-average-report-grid-header>div{
  display: flex;align-items: center;justify-content: center;
  height: fit-content;
}
.cpo-dashboard-average-report-grid-header>div>p{
  margin: 0;
  margin-left: 10px;
}
.cpo-dashboard-average-report-grid-header>div>p:last-child{
  font-weight: 500;
}
.cpo-dashboard-average-report-grid-section{
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px #D1E4E9;
  display: grid;
  grid-template-rows: 30px 1fr 1fr;
  padding: 15px 20px;
  align-items: center;
}
.cpo-dashboard-average-report-header{
  display: flex;
  align-items: center;
}
.cpo-dashboard-average-report-header>p{
  margin: 0;
}
.cpo-dashboard-average-report-header>p:last-child{
  font-weight: 600;
  margin-left: 10px;
}

.cpo-dashboard-average-report-content-container{
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-rows: 30px 1fr;
  align-items: center;
  column-gap: 30px;
  height: 70px;
}
.cpo-dashboard-average-report-image{
  grid-row: -1/1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cpo-dashboard-average-report-image>img{
  width: 100%;
  height: 70px;
  object-position: 50% 50%;
  object-fit:scale-down;
  color: #000;
}
.coinBlue{
  content: var(--coinsBlue);
}
.sandClockBlue{
  content: var(--sandClockBlue);
}

.coinGreen{
  content: var(--coinsGreen);
}
.sandClockGreen{
  content: var(--sandClockGreen);
}

.cpo-dashboard-average-report-title{
  display: flex;
  align-items: center;
}
.cpo-dashboard-average-report-title>p{
  margin: 0;
}
.cpo-dashboard-average-report-title>p:first-child{
  font-weight: 600;
  margin-right: 15px;
}
.cpo-dashboard-average-report-title>p:nth-child(2){
  position: relative;
  margin-right: 20px;
  font-weight: 500;
  color: var(--inputBorder2);
}
.cpo-dashboard-average-report-title>p:nth-child(2)::after{
  content: '%';
  position: absolute;
  right: -16px;
}
.increase{
  color: red;
  font-size: 1.1rem;
}
.decrease{
  color: var(--emoovBlue);
  font-size: 1.1rem;
}

.cpo-dashboard-average-report-context{
  display: flex;
  align-items: flex-start;
  height: 100%;
  padding-top: 10px;
}
.cpo-dashboard-average-report-context>p{
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0;
  position: relative;
}
.cpo-dashboard-average-report-context>p::after{
  font-size: 1rem;
  font-weight: normal;
  position: absolute;
  bottom: 0;
  right: -20px;
}
.cpo-dashboard-average-report-context>p.revenue::after{
  content: "원";
}
.cpo-dashboard-average-report-context>p.time::after{
  right: -35px;
  content: "시간";
}







#defaultLayout {
  padding: 10px;
}
.e-dashboardlayout.e-control .e-panel {
  background: #fff;
  box-shadow: none;
  border: 1px solid #00000000;
  box-sizing: border-box;
  position: absolute;
  border-radius: 8px;
}
.e-dashboardlayout.e-control .e-panel:hover {
  
}

.cpo-dashboard-charger-info-grid-cell{
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: 0px 0px 20px 0px #D1E4E9;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
}
.cpo-dashboard-pay-report-graph-grid-cell{
  display: grid;
  grid-template-rows: 45px 1fr;
  width: 100%;
  height: 100%;
  position: relative;
}
.cpo-dashboard-average-report-grid-cell{
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: 0px 0px 20px 0px #D1E4E9;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 55px 1fr;
  column-gap: 15px;
  padding: 15px;
}


.cpo-dashboard-guide-msg{
  display: flex;align-items: center;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: .9rem;
  color: var(--emoovBlue10);
}
.cpo-dashboard-guide-msg>svg{
  margin-left: 5px;
}

.connectorImg{
  content: var(--connectorImg);
}
.cpo-dashboard-connector-image-container{
  width: 70px;
  border-radius: 100vh;
  background: var(--emoovBlue10);
  margin-top: 20px;
  margin-right: 60px;
  position: relative;
}
.cpo-dashboard-connector-title{
  position: absolute;
  top: 15px;
  left: 20px;
  font-size: .8rem;
  font-weight: 500;
  margin: 0;
}
.cpo-dashboard-connector-image-container>img{
  width: 100%;
  height: 100%;
  object-position: 50% 50%;
  object-fit:scale-down;
  color: #000;
}

.dashboardCharger{
  content: var(--dashboard_charger);
}
.dashboardStation{
  content: var(--dashboard_station);
}

.cpo-dashboard-charger-image-container{
  width: 70px;
  height: 70px;
  border-radius: 8px;
  background: var(--emoovBlue10);
  position: relative;
  padding: 10px;
}
.cpo-dashboard-charger-image-container>img{
  width: 100%;
  height: 100%;
  object-position: 50% 50%;
  object-fit:scale-down;
  color: #000;
}