.cpo-log-report-container{
  width: 100%;
  /* max-width: 1500px; */
  height: fit-content;
  min-height: max(calc(100vh - 190px), 900px) ;
  /* border: 1px solid #000; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cpo-log-report-inner{
  width: 100%;
  max-width: 1400px;
  min-width: 1400px;
  height: 100%;
  position: relative;

}



.cpo-log-report-tab-container{
  width: 100%;
  height: 50px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-bottom: 2px solid var(--tabBorder);
}

.log-report-tab-button{
  border-radius: 7px 7px 0 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
}
.log-report-tab-bar{
  position: absolute;
  width: 25%;
  height: 3px;
  background: var(--emoovBlue);
  top: 47.5px;
  transition: .3s;
  transition: left 0.3s cubic-bezier(0.175, 0.35, 0.5, 1.13);
  z-index: 2;
}
.log-report-tab-button.selected p{
  color: var(--emoovBlue);
}
.log-report-tab-button p{
  margin: 0;
  color: var(--oxfordBlue1);
  font-weight: bold;
}
.log-report-tab-bar.tab0{
  left: 0;
}
.log-report-tab-bar.tab1{
  left: 25%;
}
.log-report-tab-bar.tab2{
  left: 50%;
}
.log-report-tab-bar.tab3{
  left: 75%;
}


.cpo-log-report-content-container{
  width: 100%;

}

.cpo-log-report-content-search-container{
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}


.cpo-log-report-content-search-filter-button{
  width: fit-content;
  height: 45px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder3);
  color: var(--inputBorder2);
  font-weight: 500;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .13s;
}

.cpo-log-report-content-search-filter-button:active{
  opacity: 0.3;
}
.cpo-log-report-content-search-filter-button>svg{
  margin-left: 10px;
  font-size: 1.2rem;
}

.cpo-log-report-content-search-input-container{
  display: grid;
  grid-template-columns: 1fr 20px;
  column-gap: 5px;
  align-items: center;
  height: 30px;
  width: 300px;
  border-bottom: 2px solid var(--inputBorder2);
  position: relative;
  margin-left: 30px;
  padding: 0 5px 5px 5px;
  transition: .3s;
}
.cpo-log-report-content-search-input-container:has(input:focus){
  border-bottom: 2px solid var(--emoovBlue);
  
}
.cpo-log-report-content-search-input-container input{
  all: unset;
  width: 100%;
  height: 100%;
}
.cpo-log-report-content-search-input-container>svg{
  color: var(--inputBorder2);
  cursor: pointer;
  transition: .13s;
}
.cpo-log-report-content-search-input-container>svg:active{
  opacity: 0.13;
}

.cpo-log-report-content-item-list-container{
  width: 100%;
  min-height: 300px;
  border-top: 2px solid var(--inputBorder2);
}
.cpo-log-report-content-item-column{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 1.8fr 1fr 1.3fr 1fr 0.5fr;
  column-gap: 10px;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;

  font-size: .9rem;
  color: var(--inputBorder2);
}
.cpo-log-report-content-item-column.log-tab0{
  grid-template-columns: 1fr 1.3fr 1fr 1.3fr;
}
.cpo-log-report-content-item-column.log-tab1{
  grid-template-columns: 1fr 1fr 1.5fr 1.5fr 1fr 1fr;
}
.cpo-log-report-content-item-column.log-tab2{
  grid-template-columns: 1fr 1fr 1.3fr 4fr;
}
.cpo-log-report-content-item-column.log-tab3{
  grid-template-columns: 1fr 1fr 1.3fr 2fr 3fr 0.6fr;
}

.cpo-log-report-content-item{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 1.8fr 1fr 1.3fr 1fr 0.5fr;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  column-gap: 10px;
  font-size: .9rem;
}


.cpo-log-report-content-item.log-tab0{
  grid-template-columns: 1fr 1.3fr 1fr 1.3fr;
}
.cpo-log-report-content-item.log-tab1{
  grid-template-columns: 1fr 1fr 1.5fr 1.5fr 1fr 1fr;
}
.cpo-log-report-content-item.log-tab2{
  grid-template-columns: 1fr 1fr 1.3fr 4fr;
}
.cpo-log-report-content-item.log-tab3{
  display: block;
  width: 100%;
  height: 50px;
  padding: 0;
}
.cpo-log-report-messageType{
  width: fit-content;
  /* height: fit-content; */
  padding: 5px 10px;
  border-radius: 100vh;
  font-size: 0.9rem;
  font-weight: 500;
  background: #fff;
  box-shadow: 0 0 0 1px var(--emoovBlue);
  color: var(--emoovBlue);
}
.cpo-log-report-messageType p{
  margin: 0;
}
.cpo-log-report-messageType.request{
  box-shadow: 0 0 0 1px var(--emoovBlue);
  color: var(--emoovBlue);
}
.cpo-log-report-messageType.confirm{
  box-shadow: 0 0 0 1px var(--textGreen);
  color: var(--textGreen);
}
.cpo-log-report-messageType.error{
  box-shadow: 0 0 0 1px var(--textRed);
  color: var(--textRed);
}


.cpo-log-report-content{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1.3fr 2fr 3fr 0.6fr;
  column-gap: 10px;
  align-items: center;
  padding: 0 20px;
}
.cpo-log-report-content>div:last-child{
  display: flex;align-items: center;
  cursor: pointer;
}
.cpo-log-report-content>div:last-child>svg{
  margin-left: 5px;
  transition: .13s;
}
.cpo-log-report-content-item.log-tab3.open .cpo-log-report-content>div:last-child>svg{
  transform: rotate(-180deg);
}
.cpo-log-report-content-hidden{
  background: #F7F7F7;
  visibility: hidden;
  max-height: 0;
  /* padding: 0 20px; */
}
.cpo-log-report-content-item.log-tab3.open{
  margin-bottom: 70px;
}
.cpo-log-report-content-item.log-tab3.open .cpo-log-report-content-hidden>div>svg{
  transform: rotate(-180deg);
}
.cpo-log-report-content-item.log-tab3.open .cpo-log-report-content-hidden{
  visibility: visible;
  max-height: none;
  padding:  20px;
  border-top: 1px solid var(--inputBorder3);
  border-bottom: 1px solid var(--inputBorder3);
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.cpo-log-report-content-item.log-tab3.open .cpo-log-report-content-hidden>div{
  display: flex;
}
.cpo-log-report-content-item.log-tab3.open .cpo-log-report-content-hidden>div>p:first-child{
  color: var(--emoovBlue);
  font-weight: 600;
  margin-right: 10px;
}








.cpo-log-report-content-item p{
  margin: 0;
}

.cpo-log-report-content-item-authorization{
  width: fit-content;
  display: flex;
  align-items: center;  justify-content: center;
  padding: 5px 15px;
  border-radius: 100vh;
  border: 2px solid;
  font-size: .8rem;
  font-weight: 600;
}

.cpo-log-report-content-item-authorization.Accepted{
  border-color: var(--emoovBlue);
  color: var(--emoovBlue);
}
.cpo-log-report-content-item-authorization.Blocked{
  border-color: var(--textRed);
  color: var(--textRed);
}
.cpo-log-report-content-item-authorization.Expired{
  border-color: var(--inputBorder2);
  color: var(--inputBorder2);
}
.cpo-log-report-content-item-authorization.Invalid{
  border-color: var(--textYellow);
  color:var(--textYellow);
}



.cpo-log-report-detail-button{
  cursor: pointer;
  color: var(--inputBorder);
  width:fit-content;
  border-bottom: 1px solid var(--inputBorder);
}




/* CpoLogSearchModal */
.cpo-log-report-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-log-report-modal-container{
  position: relative;
  max-width: 1100px;
  width: fit-content;
  height: fit-content;
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  /* overflow: hidden; */
  padding: 20px 30px;
  z-index: 1000;
}
.cpo-log-report-modal-header{
  width: 100%;
  margin-bottom: 30px;
  display: flex; align-items: center;
}
.cpo-log-report-modal-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}
.cpo-log-report-modal-header p{
  color: var(--inputBorder2);
}
.cpo-log-report-modal-close-button{
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: .13s;
}
.cpo-payment-function-modal-close-button:active{
  opacity: 0.3;
}


/* .cpo-log-report-modal-content{
  width: 550px;
} */
.cpo-log-report-modal-content-box.log-tab0{
  width: 700px;
}
.cpo-log-report-modal-content-box.log-tab1{
  width: 900px;
}
.cpo-log-report-modal-content-box.log-tab2{
  width: 700px;
}
.cpo-log-report-modal-content-box.log-tab3{
  width: 700px;
}

.cpo-log-report-modal-content>div{
  margin: 20px 0;
}
.cpo-log-report-modal-content>div:nth-child(2){
  margin-top: 40px;
}


.cpo-log-report-modal-content-wrapper{
  width: 100%;
  margin-bottom: 15px;
  position: relative;
}
.cpo-log-report-modal-content-wrapper.divide{
  display: grid;
  grid-template-columns: 1fr 60px 1fr;
  width: 100%;
}
.cpo-log-report-modal-content-wrapper.divide.tab0{
  width: 80%;
}
.cpo-log-report-modal-content-wrapper.divide>div:nth-child(2){
  display: flex;align-items: center;justify-content: center;
  font-size: 1.2rem;
}
.cpo-log-report-modal-content-wrapper.marginTop{
  margin-top: 50px;
}
.cpo-log-report-modal-content-wrapper.subTitle{
  display: flex;
  align-items: center;
}
.cpo-log-report-modal-content-wrapper.subTitle>p:last-child{
  margin-left: 10px;
  font-size: .9rem;
  color: var(--inputBorder);
}
.cpo-log-report-modal-content-wrapper>p{
  font-weight: 500;
  margin: 0;
  margin-bottom: 15px;
}
.cpo-log-report-modal-content-input-container{
  width: 100%;
  height: 45px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder3);
  padding: 0 10px;
  position: relative;
}
.cpo-log-report-modal-content-input-container input{
  all: unset;
  height: 100%;
  width: 100%;
}


.cpo-log-report-modal-content-input-container.postfix{
  display: grid;
  grid-template-columns: 1fr 20px;
  align-items: center;
}
.cpo-log-report-modal-content-input-container.postfix>p{
  margin: 0;
}
.cpo-log-report-modal-content-input-container.postfix2{
  display: grid;
  grid-template-columns: 1fr 40px;
  align-items: center;
}
.cpo-log-report-modal-content-input-container.postfix2>p{
  margin: 0;
}
.cpo-log-report-modal-content-input-container.half{
  width: 45%;
}
.cpo-log-report-modal-content-input-container.time input{
  text-align: center;
  font-size: 1.2rem;
}

.cpo-log-report-modal-content-input-wrapper{
  display: grid;
  grid-template-columns: 5fr 4fr;
  position: relative;
  width: 100%;
  column-gap: 10px;
}

.cpo-log-report-modal-content-toggle-container{
  display: flex;
  align-items: center;
  width: 60%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
}

.cpo-log-report-modal-content-toggle{
  width: 100%;
  height: 35px;
  border-radius: 100vh;
  display: flex;align-items: center;justify-content: center;
  border: 1px solid #F0F0F0;
  background: #F0F0F0;
  cursor: pointer;
  transition: .13s;
  font-size: .9rem;
  color: var(--inputBorder);
}
.cpo-log-report-modal-content-toggle.failure{
  border-color: var(--textRed);
  color: var(--textRed);
  background: #fff;
}
.cpo-log-report-modal-content-toggle.success{
  border-color: var(--emoovBlue);
  color: var(--emoovBlue);
  background: #fff;
}


.cpo-log-report-modal-content-selector-container{
  width: 100%;
  border-radius: 8px;
  height: 45px;
}
.cpo-log-report-modal-content-selector-container .selector{

  border: 1px solid var(--inputBorder3);
  
}

.cpo-log-report-modal-content-badge-input-container{
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder3);
  padding: 0 10px;
}

.cpo-log-report-modal-content-badge-item-list-container{
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.cpo-log-report-modal-content-badge-item{
  width: fit-content;
  display: flex;
  align-items: center;  justify-content: center;
  padding: 5px 10px;
  border-radius: 100vh;
  border: 2px solid;
  font-size: .8rem;
  font-weight: 600;
  margin-right: 10px;
  cursor: pointer;
  transition: .13s;
}
.cpo-log-report-modal-content-badge-item:active{
  opacity: 0.3;
}
.cpo-log-report-modal-content-badge-item.Accepted{
  border-color: var(--emoovBlue);
  color: var(--emoovBlue);
}
.cpo-log-report-modal-content-badge-item.Blocked{
  border-color: var(--textRed);
  color: var(--textRed);
}
.cpo-log-report-modal-content-badge-item.Expired{
  border-color: var(--inputBorder2);
  color: var(--inputBorder2);
}
.cpo-log-report-modal-content-badge-item.Invalid{
  border-color: var(--textYellow);
  color:var(--textYellow);
}
.cpo-log-report-modal-content-badge-item.ConcurrentTx{
  border-color: var(--textGreen);
  color: var(--textGreen);
}

.cpo-log-report-modal-content-badge-item.hidden{
  transition: 0s;
  visibility: hidden;
  opacity: 0;
  padding: 0;
  margin: 0;
  width: 0;
  border: none;
}
.cpo-log-report-modal-content-badge-item>svg{
  font-size: .65rem;
  margin-left: 5px;
}





.cpo-log-report-modal-content-data-setting-badge-container{
  display: flex;
  flex-wrap: wrap;
}
.cpo-log-report-modal-content-data-setting-badge{
  padding: 10px 15px;
  /* border: 1px solid #000; */
  background: #F0F0F0;
  border-radius: 100vh;
  margin-right: 15px;
  margin-bottom: 20px;
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--inputBorder);
  cursor: pointer;
  transition: .3s;
}

.cpo-log-report-modal-content-data-setting-badge.selected{
  box-shadow: 0 0 0 1px var(--emoovBlue);
  color: var(--emoovBlue);
  background: #fff;
}

.cpo-log-report-modal-content-data-setting-badge.messageType.selected:nth-child(2){
  box-shadow: 0 0 0 1px var(--textGreen);
  color: var(--textGreen);
  background: #fff;
}
.cpo-log-report-modal-content-data-setting-badge.messageType.selected:nth-child(3){
  box-shadow: 0 0 0 1px var(--textRed);
  color: var(--textRed);
  background: #fff;
}


.cpo-log-report-modal-content-data-setting-badge.result.selected.Accepted{
  box-shadow: 0 0 0 1px var(--emoovBlue);
  color: var(--emoovBlue);
}
.cpo-log-report-modal-content-data-setting-badge.result.selected.Blocked{
  box-shadow: 0 0 0 1px var(--textRed);
  color: var(--textRed);
}
.cpo-log-report-modal-content-data-setting-badge.result.selected.Expired{
  box-shadow: 0 0 0 1px var(--inputBorder2);
  color: var(--inputBorder2);
}
.cpo-log-report-modal-content-data-setting-badge.result.selected.Invalid{
  box-shadow: 0 0 0 1px var(--textYellow);
  color:var(--textYellow);
}
.cpo-log-report-modal-content-data-setting-badge.result.selected.ConcurrentTx{
  box-shadow: 0 0 0 1px var(--textGreen);
  color: var(--textGreen);
}






.cpo-log-report-modal-button-container{
  display: flex;
  align-items: center;
  justify-content: end;
}
.cpo-log-report-modal-button{
  height: 45px;
  padding: 0 25px;
  border-radius: 8px;
  background: #3d3d3d;
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;justify-content: center;
  cursor: pointer;
  transition: .13s;
}
.cpo-log-report-modal-button:nth-child(1){
  background: #fff;
  border: 1px solid var(--inputBorder2);
  color: var(--inputBorder2);
  margin-right: 10px;
  padding: 0 15px;

}
.cpo-log-report-modal-button:active{
  opacity: 0.3;
}





/* CpoLogDetailModal */

.cpo-log-detail-info-container{
  display: grid;
  width: 100%;
  height: 250px;
  border-radius: 8px;
  grid-template-rows: 1fr 1fr 1fr;
  border: 1px solid var(--inputBorder3);
}

.cpo-log-detail-info-row{
  border-bottom: 1px solid var(--inputBorder3);
}
.cpo-log-detail-info-row>p:first-child{
  color: var(--inputBorder);
}
.cpo-log-detail-info-row:nth-child(1){
  padding: 0 10px;
}
.cpo-log-detail-info-row:last-child{
  border-bottom: none;
}
.cpo-log-detail-info-row.grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.cpo-log-detail-info-row.grid>div{
  border-right: 1px solid var(--inputBorder3);
  padding: 0 10px;
}

.cpo-log-detail-info-row.grid>div>p:first-child{
  color: var(--inputBorder);
}
.cpo-log-detail-info-row.grid>div>p:last-child{
  margin: 0;
}
.cpo-log-detail-info-row.grid>div:last-child{
  border-right: none;
}

.cpo-log-report-modal-content-title{
  margin-top: 50px;
}
.cpo-log-detail-json-container{
  border-radius: 8px;
  width: 100%;
  height: 400px;
  overflow: scroll;
  padding-top: 15px;
}
.cpo-log-detail-info-row p.success{
  color: var(--emoovBlue);
}
.cpo-log-detail-info-row p.error{
  color: var(--textRed);
}









/* filter item */
.cpo-log-report-filter-item-container{
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.cpo-log-report-filter-item{
  width: fit-content;
  height: 45px;
  border-radius: 8px;
  display: flex;align-items: center;justify-content: center;
  border: 1px solid var(--inputBorder3);
  background: #F0F0F0;
  transition: .13s;
  font-size: .9rem;
  color: var(--inputBorder2);
  margin-right: 10px;
  padding: 0 10px;
}
.cpo-log-report-filter-item>svg:active{
  opacity: 0.3;
}
.cpo-log-report-filter-item>svg{
  margin: 0;
  margin-left: 5px;
  font-size: 1.3rem;
  cursor: pointer;
  transition: .3s;
}