.label-main-template-container {
  overflow-y: scroll;
  height: 100%;
  width: 100vw;
  background: #fafafa;
}
.label-main-template-container footer-image{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
  background: #222;
}
.label-main-template-container footer-image>img{
  width: 100%;
  min-width: 1200px;
  max-width: 1600px;
}
.label-main-template-container footer-image>p{
  display: inline-block;
  color: #777;
  font-size: .9rem;
  font-weight: 600;
  width: 0;
  transform: translateX(-150px);
  padding-bottom: 10px;
}
.label-main-template-container section{
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 150px);
  height: fit-content;
  padding: 0;
  position: relative;
  width: 100%;
  background: var(--bgWhite);
}

.label-main-template-container footer{
  background:#222;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 150px;
  padding-top: 20px;
  position: relative;
}
.label-main-template-container footer>div{
  width: 1200px;
  border-top: 1px solid var(--inputBorder2);
  padding-top: 15px;
}
.label-main-template-footer-text{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.label-main-template-footer-text>span{
  font-size: .9rem;
  margin-right: 40px;
  font-weight: 500;
}

.label-init-page-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 600px;
  /* padding: 200px 0; */
  margin: 30px 0;
  border-radius: 8px;
  width: 900px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
}

.label-init-page-title {
  font-size: .9rem;
  font-weight: bold;
  color: var(--inputBorder);
  width: 400px;
  display: inline-flex;
  justify-content: space-between;
}

.label-init-page-title span {
  flex-grow: 1; /* 모든 글자와 공백이 동일한 너비를 가지도록 설정 */
  text-align: center; /* 개별 문자 중앙 정렬 */
  white-space: pre; /* 공백 유지 */
}

.label-init-page-logo-container{
  width: 360px;
  height: fit-content;
}
.label-init-page-logo{
  width: 100%;
  height: 100%;
  object-position: 50% 50%;
  object-fit:contain;
  /* content: var(--logo); */
}
/* .label-init-page-logo {
  background: no-repeat 50% / cover;
  flex: none;
  height: 100%;
  width: 100%;
} */

.label-init-page-button{
  width: 400px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1.2rem;
  border-radius: 8px;
  cursor: pointer;
  transition: .13s;
  background: #fff;
}
.label-init-page-button:active{
  opacity: 0.3;
}
.label-init-page-button.navigator{
  box-shadow: 0 0 0 3px var(--emoovBlue);
  color: var(--emoovBlue);
  background: #fff;
  /* margin-top: 30px; */
}
.label-init-page-button.logout{
  margin-top: 30px;
  background: var(--textRed1);
  color: #fff;
}
.label-init-page-button.signup{
  margin-top: 30px;
  box-shadow: 0 0 0 3px var(--emoovBlue);
  color: var(--emoovBlue);
  background: #fff;
}
.label-init-page-button.login{
  background: var(--emoovBlue);
  color: #fff;
}
.label-init-page-user-info{
  width: 500px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.label-init-page-user-info>span{
  font-weight: 600;
  font-size: 1.2rem;
}
.label-init-page-user-info>span:first-child{
  color: var(--emoovBlue);
}






/* Fast_Enroll */
.label-fast-enroll-logo{
  width: 80%;
  top: 20%;
  position: absolute;
}



.go-to-initial-button{
  position: absolute;
  left: 15px;
  top: 15px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: .13s;
}
.go-to-initial-button:active{
  opacity: 0.3;
}
/* Login */
.login-page-container.label{
  position: relative;
}
/* SignUp */
.sign-up-page-container.label{
  position: relative;
}
.sign-up-tab-container.sign-up-tab1.label{
  display: flex;
  align-items: center;
  justify-content: center;
}

/* MemberWithdraw */
.cpo-member-withdraw-button.submit.label{
  pointer-events: none;
  background: #fff;
  border: 3px solid var(--emoovBlue);
  color: var(--emoovBlue);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cpo-member-withdraw-button.submit.label>p{
  margin: 0;
}
.cpo-member-withdraw-button.submit.label>p:last-child{
  font-size: .95rem;
}