.cpo-ev-user-management-container{
  width: 100%;
  /* max-width: 1500px; */
  height: fit-content;
  min-height: max(calc(100vh - 190px), 900px) ;
  /* border: 1px solid #000; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cpo-ev-user-management-inner{
  width: 100%;
  max-width: 1400px;
  min-width: 1400px;
  height: 100%;
  position: relative;

}


.cpo-ev-user-management-content-container{
  width: 100%;

}
.cpo-ev-user-management-content-container.grid{
  display: grid;
  grid-template-columns: 1fr 500px;
}

.cpo-ev-user-management-content-header{
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.cpo-ev-user-management-content-item-list-container{
  width: 100%;
  min-height: 300px;
  border-top: 2px solid var(--inputBorder2);
}
.cpo-ev-user-management-content-item-column{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 0.5fr 1.2fr 0.6fr 1.8fr 60px;
  column-gap: 10px;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;

  font-size: .9rem;
  color: var(--inputBorder2);
}





.cpo-ev-user-management-content-item{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 0.5fr 1.2fr 0.6fr 1.8fr 60px;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  column-gap: 10px;
  font-size: .9rem;
  cursor: pointer;
}

.cpo-ev-user-management-content-item p{
  margin: 0;
}

.cpo-ev-user-management-content-item>div{
  display: flex;align-items: center;
}
.cpo-ev-user-management-content-item>div>svg{
  margin-left: 5px;
  transition: .13s;
}

.cpo-ev-user-management-content-item-content{
  background: #F7F7F7;
  visibility: hidden;
  max-height: 0;
  /* padding: 0 20px; */
}
.cpo-ev-user-management-content-item-content>div{
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 15px;
}
.cpo-ev-user-management-content-item-content>div:last-child{
  margin-bottom: 15px;
}
.cpo-ev-user-management-content-item-content>div>p{
  margin: 0;
  margin-right: 10px;
  color: var(--emoovBlue);
  font-weight: 500;
}

.cpo-ev-user-management-content-item-container.open .cpo-ev-user-management-content-item-content{
  visibility: visible;
  max-height: none;
  border-bottom: 1px solid var(--inputBorder3);
  padding-top: 15px;
}
.cpo-ev-user-management-content-item-container.open .cpo-ev-user-management-content-item>div>svg{
  transform: rotate(-180deg);
}


.cpo-ev-user-management-content-item-content>div.cpo-ev-user-management-id-tag-list-container{
  display: block;
  padding: 0;
  width: 100%;
}

.cpo-ev-user-management-id-tag-list-container{
  width: 100%;
}
.cpo-ev-user-management-id-tag-list-item-column{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 30px 1.15fr 1fr 1fr;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  border-top: 1px solid var(--inputBorder2);
  padding: 0 20px;
  column-gap: 10px;
  font-size: .9rem;
}
.cpo-ev-user-management-id-tag-list-item-container{
  width: 100%;
  overflow-y: overlay;
  scrollbar-gutter: stable;
  padding: 0 15px;
}

.cpo-ev-user-management-id-tag-list-item{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 30px 1.15fr 1fr 1fr;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  column-gap: 10px;
  font-size: .9rem;

}
.cpo-ev-user-management-id-tag-list-item>div{
  display: flex;
  align-items: center;
}



.cpo-ev-user-management-card-list-item-column{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 30px 60px 1fr 60px 60px 2fr 2fr;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  border-top: 1px solid var(--inputBorder2);
  padding: 0 20px;
  column-gap: 10px;
  font-size: .9rem;
}
.cpo-ev-user-management-card-list-item{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 30px 60px 1fr 60px 60px 2fr 2fr;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  column-gap: 10px;
  font-size: .9rem;

}
.cpo-ev-user-management-card-list-item>div{
  display: flex;
  align-items: center;
  position: relative;
}
.cpo-ev-user-management-card-list-item>div>img{
  width: 2.5rem;
  object-position: 50% 50%;
  object-fit:cover;
  color: #000;
  margin-right: 30px;
}