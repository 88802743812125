/* ************************************************************ */
/* Faq */
/* ************************************************************ */
.cpo-customer-service-container{
  width: 100%;
  /* max-width: 1500px; */
  height: fit-content;
  min-height: calc(100vh - 190px);
  /* border: 1px solid #000; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cpo-customer-service-inner{
  width: 100%;
  max-width: 1400px;
  min-width: 850px;
  height: 100%;
  position: relative;
}
.cpo-faq-container{
  display: grid;
  grid-template-rows: 90px auto;
  position: relative;
}
.cpo-faq-header{
  position: relative;
  transition: .3s;
}
.cpo-faq-header.modalOpen{
  margin-top: 450px;
}
.cpo-faq-append-button{
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  width: 100px;
  background: var(--emoovBlue);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .1s;
}
.cpo-faq-append-button p{
  margin: 0;
  color: #fff;
  font-size: .9rem;
  font-weight: bold;
}
.cpo-faq-append-button:active{
  opacity: .5;
}
.cpo-faq-tab-container{
  margin-top: 40px;
  width: 100%;
  height: 50px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  border-bottom: 2px solid var(--tabBorder);
}

.faq-tab-button{
  border-radius: 7px 7px 0 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
}
.faq-tab-bar{
  position: absolute;
  width: 11.11111%;
  height: 3px;
  background: var(--emoovBlue);
  top: 47.5px;
  transition: .3s;
  transition: left 0.3s cubic-bezier(0.175, 0.35, 0.5, 1.13);
  z-index: 2;
}
.faq-tab-button.selected p{
  color: var(--emoovBlue);
}
.faq-tab-button p{
  margin: 0;
  color: var(--oxfordBlue1);
  font-weight: bold;
}
.faq-tab-bar.tab0{
  left: 0;
}
.faq-tab-bar.tab1{
  left: 11.1111%;
}
.faq-tab-bar.tab2{
  left: 22.2222%;
}
.faq-tab-bar.tab3{
  left: 33.3333%;
}
.faq-tab-bar.tab4{
  left: 44.6%;
}
.faq-tab-bar.tab5{
  left: 55.5555%;
}
.faq-tab-bar.tab6{
  left: 66.8%;
}
.faq-tab-bar.tab7{
  left: 77.85%;
}
.faq-tab-bar.tab8{
  left: 88.8889%;
}
.cpo-faq-list-container.modalOpen{
  margin-top: 450px;
}
.cpo-faq-list-container{
  min-height: 700px;
  padding: 10px;
  transition: .3s;
}
.cpo-faq-item-container{
  display: grid;
  grid-template-columns: 130px 1fr 200px;
  grid-template-rows: 70px auto;
  /* border-radius: 5px; */
  cursor: pointer;
  transition: .3s;
  position: relative;
  /* margin-bottom: 10px; */
  border-bottom: 1px solid var(--inputBorder3);
}
/* .cpo-faq-item-container:hover{
  background: var(--hoverGray);
  background: #f2f4f6;
} */
.cpo-faq-item-container.clicked .cpo-faq-item-category-content-container{
  /* background: var(--hoverGray); */
  background: #f7f7f7;
  border-top: 1px solid var(--inputBorder3);
}
.cpo-faq-item-container>div{
  display: flex;
  align-items: center;
}
.cpo-faq-item-container p{
  margin: 0;
}
.cpo-faq-item-category-container{
  /* justify-content: center; */
  padding-left: 10px;
}
.cpo-faq-item-category-container p{
  font-size: 1rem;
  color: var(--inputBorder2);
}
.cpo-faq-item-container>div.cpo-faq-item-category-title-container{
  display: grid;
  grid-template-columns: 30px 1fr;
  justify-content: start;
}
.cpo-faq-item-category-title-container p{
  font-size: 1rem;
  font-weight: bold;
}
.cpo-faq-item-category-title-container p:last-child{
  font-weight: 500;
}
.cpo-faq-item-category-content-container{
  grid-column: -1/1;
  padding-left: 130px;
  padding-right: 10px;
  align-items: start;
  justify-content: start;
  transition: .5s;
  max-height: 0px;
  width: calc(100%);
}

.cpo-faq-item-container>div.cpo-faq-item-category-content-container{
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: start;
  /* padding-right: 200px; */
}

.cpo-faq-item-category-content-container.clicked{
  padding: 30px 200px 30px 130px;
  max-height: 800px;
}
.cpo-faq-item-category-content-container>p{
  font-weight: bold;
  max-height: 0;
  opacity: 0;
  transition: 0.45s;
  margin-bottom: 30px;
}
.cpo-faq-item-category-content-container.clicked>p{
  margin-bottom: 30px;
  font-weight: bold;
  opacity: 1;
}
.cpo-faq-item-category-content-container pre{
  margin: 0;
  margin-bottom: 15px;
  max-height: 0;
  opacity: 0;
  transition: 0.45s;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-all;
  overflow: auto;
}
.cpo-faq-item-category-content-container.clicked pre{
  width: 100%;
  max-height: 800px;
  opacity: 1;
  transition: 0.5s;
  white-space: pre-wrap;
  word-break: break-all;
  overflow: auto;
}
.cpo-faq-item-owner-container{
  width: 80px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  /* justify-content: center; */
}
.cpo-faq-item-owner{
  width: fit-content;
  padding: 0 5px;
  height: 25px;
  border-radius: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cpo-faq-item-owner.common{
  background: #b9bbbb;
}
.cpo-faq-item-owner.cpo{
  background: #fff;
  border: 2px solid var(--emoovBlue);

}
.cpo-faq-item-owner p{
  margin: 0;
  font-size: .8rem;
  font-weight: bold;
}
.cpo-faq-item-owner.common p{
  color: #fff;
}
.cpo-faq-item-owner.cpo p{
  color: var(--emoovBlue);
}

.cpo-faq-item-function-button-container{
  height: 50px;
  width: fit-content;
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: .3s;
  background: #fff;
}
.cpo-faq-item-function-button-container{
  font-size: 1.5rem;
}
.cpo-faq-item-function-button-container:hover{
  /* background: #e4e8e9; */
}
.cpo-faq-item-function-button-container:has(.cpo-fap-item-function-button-list.open){
  box-shadow: 0px 0px 4px 0px #00000040;
  
}
.cpo-fap-item-function-button-list{
  display: flex;
  align-items: center;
  justify-content: center;
  visibility:hidden;
  max-width: 0;
  
}
.cpo-fap-item-function-button-list.open{
  max-width: 150px;
  visibility: visible;
}
.cpo-fap-item-function-button-list>div:first-child::after{
  content: "";
  display: block;
  position: absolute;
  right: 0px;
  width: 1.2px;
  height: 50%;
  background: #E2E2E2;
}
.cpo-fap-item-function-button-list>div{
  height: 40px;
  width: 50px;
  /* margin-left: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.cpo-fap-item-function-button{
  width: 85%;
  height: 85%;
  /* border-radius: 100vh; */
  /* background: #f6fbfc; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* transition: .1s; */
}
.cpo-fap-item-function-button:active{
  opacity: 0.5;
}
.cpo-fap-item-function-button>div{
  width: 85%;
  height: 85%;
  /* border-radius: 100vh; */
  /* background: var(--buttonBlueOuter); */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6E6E6E;
  transition: background .3s;
  font-size: 1.2rem;
}
.cpo-fap-item-function-button>div:hover{
  /* background: #b4eafa; */
}
.cpo-fap-item-function-button.delete>div{
  /* background: var(--buttonRed); */
  color: #FF295D;
  transition:background .3s;
  font-size: 1.1rem;
}
.cpo-fap-item-function-button.delete>div:hover{
  /* background: #ffa4a4; */
}
.cpo-fap-item-function-button>div p{
  margin: 0;
  font-weight: bold;
}






/* ************************************************************ */
/* FaqEnrollModal */
/* ************************************************************ */

.cpo-faq-enroll-modal-container{
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  border: 1.5px solid var(--inputBorder3);
  box-shadow: 0.5px 1px 3px var(--oxfordBlue1);
  border-radius: 5px;
  z-index: 9;
  min-width: 100%;
  transition: .3s;
  visibility: hidden;
  opacity: 0;
}
.cpo-faq-enroll-modal-container.modalOpen{
  width: 100%;
  height: 500px;
  opacity: 1;
  visibility: visible;
}
.cpo-faq-enroll-modal-close-btn{
  transform: translateY(-50%);
  position: absolute;
  top: 20px;
  right: 5px;
  font-size: 1.5rem;
  color: #949697;
  cursor: pointer;
}
.cpo-faq-enroll-modal-inner{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-template-rows: 70px 1fr;
}

.cpo-faq-enroll-modal-content-container{
  grid-column: -1/1;
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-template-rows: 60px 1fr;
  width: 100%;
  padding-top: 15px;
  background: #fbfbfb;
}
.cpo-faq-enroll-modal-header{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.faq-enroll-title{
  display: flex;
  align-items: center;
  justify-content: center;
}
.faq-enroll-title.answer{
  align-items: start;
  margin-top: 30px;
}
.faq-enroll-title p{
  margin: 0;
  font-weight: bold;
  font-size: .9rem;
  /* margin-left: 20px; */
}
.faq-selector-container{
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.faq-selector-container>div{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.faq-selector-container>div:first-child{
  height: 45px;
}
.faq-selector-container .selector-item-list{
  width: 100%;
}
.faq-selector-container .selector-check-icon{
  right: 5px;
}
.faq-selector-container .selector-item p{
  color: #000;
}
.faq-selector-container .selector p{
  color: #000;
}

.cpo-faq-enroll-modal-button-container{
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.cpo-faq-enroll-modal-button{
  width: fit-content;
  padding: 0 15px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .9rem;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  transition: .13s;
}
.cpo-faq-enroll-modal-button:active{
  opacity: 0.3;
}
.cpo-faq-enroll-modal-button.enroll{
  background: var(--emoovBlue);
  color: #fff;
  margin-left: 15px;
}
.cpo-faq-enroll-modal-button.enroll.disabled{
  opacity: 0.3;
  pointer-events: none;
}
.cpo-faq-enroll-modal-button.cancel{
  border: 1px solid var(--inputBorder3);
  color: (--inputBorder2);
}



.faq-title-input-container{
  height: 100%;
  width: 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;
  transition: .3s;
  padding-right: 20px;
  padding-left: 20px;
}
.faq-title-input-container form{
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  border: 1.5px solid var(--inputBorder3);
  border-radius: 8px;
  background: #fff;
  padding: 0 10px;
}
.faq-title-input-container input.hidden{
  width: 0;margin: 0;
}
.faq-title-input-container input{
  all: unset;
  height: 40px;
  font-weight: 500;
  font-size: .9rem;
  width: 100%;
  /* font-size: 1.1rem; */
}

.cpo-faq-enroll-modal-answer-container{
  width: 100%;
  height: 100%;
  /* grid-column: -1/1; */
  padding: 20px;
}
.cpo-faq-enroll-modal-answer{
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1.5px solid var(--inputBorder3);
  position: relative;
  padding: 10px;
  background: #fff;
}
.cpo-faq-enroll-modal-answer textarea{
  all: unset;
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 1.5rem;
  font-size: .9rem;
}
.cpo-faq-enroll-button{
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  background: var(--emoovBlue);
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: .1s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cpo-faq-enroll-button:active{
  opacity: 0.5;
}
.cpo-faq-enroll-button p{
  margin: 0;
  font-size: .9rem;
  color: #fff;
  font-weight: bold;
}
.cpo-faq-enroll-button.disabled{
  opacity: 0.2;
  pointer-events: none;
}



/* ************************************************************ */
/* FaqDeleteModal */
/* ************************************************************ */

.faq-delete-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.faq-delete-modal-container{
  position: relative;
  min-width: 500px;
  min-height: 330px;
  height: fit-content;
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  /* overflow: hidden; */
  padding: 20px;
  z-index: 1000;
  display: grid;
  grid-template-rows: auto 1fr 50px;
}
.faq-delete-modal-header{
  width: 100%;
  margin-bottom: 30px;
}
.faq-delete-modal-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}
.faq-delete-modal-list-container{
  display: flex;
  align-items: center;
  justify-content: center;
}
.faq-delete-modal-msg{
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
}

.faq-delete-modal-container>div:last-child{
  display: flex;align-items: center;
  justify-content: space-between;
  
}
.faq-delete-modal-delete-button{
  width: 	100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
  transition: .13s;
  background: var(--cardBtnRed);
  cursor: pointer;
}
.faq-delete-modal-delete-button:active{
  opacity: 0.3;
}
.faq-delete-modal-delete-button p{
  margin: 0;
  font-weight: 600;
  font-size: 1rem;
  color: var(--textRed1);
}



/* ************************************************************ */
/* Notice */
/* ************************************************************ */

.cpo-notice-container{
  display: grid;
  grid-template-rows: 120px auto;
  position: relative;
}
.cpo-notice-header{
  position: relative;
  transition: .3s;
}
.cpo-notice-append-button{
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  width: 100px;
  background: var(--emoovBlue);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .1s;
}
.cpo-notice-append-button p{
  margin: 0;
  color: #fff;
  font-size: .9rem;
  font-weight: bold;
}
.cpo-notice-append-button:active{
  opacity: .5;
}
.cpo-notice-list-container{
  min-height: 700px;
  padding: 10px;
  transition: .3s;
  /* border-bottom: 1.5px solid #ddd; */
}


.cpo-notice-item-container{
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-template-rows: 70px;
  border-radius: 5px;
  cursor: pointer;
  transition: .3s;
  position: relative;
  margin-bottom: 10px;
}
.cpo-notice-item-container:hover{
  background: var(--hoverGray);
  background: #f2f4f6;
}
/* .cpo-notice-item-container.clicked{
  background: var(--hoverGray);
  background: #f2f4f6;
} */
.cpo-notice-item-container>div{
  display: flex;
  align-items: center;
}
.cpo-notice-item-container p{
  margin: 0;
}
.cpo-notice-item-category-container{
  /* justify-content: center; */
  padding-left: 10px;
}
.cpo-notice-item-category-container p{
  font-size: 1rem;
}
.cpo-notice-item-category-title-container{
  justify-content: start;
}
.cpo-notice-item-category-title-container p{
  font-size: 1rem;
  font-weight: bold;
}
.cpo-notice-item-category-content-container{
  grid-column: -1/1;
  padding-left: 80px;
  padding-right: 10px;
  align-items: start;
  justify-content: start;
  transition: .5s;
  max-height: 0px;
  width: calc(100%);
}
.cpo-notice-item-category-content-container pre{
  margin: 0;
  margin-bottom: 15px;
  max-height: 0;
  opacity: 0;
  transition: 0.45s;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-all;
  overflow: auto;
}

.cpo-notice-item-owner-container{
  width: 80px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  /* justify-content: center; */
}
.cpo-notice-item-owner{
  width: fit-content;
  padding: 0 10px;
  height: 25px;
  border-radius: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #b9bbbb;
}
.cpo-notice-item-owner p{
  color: #fff;
  margin: 0;
  font-size: .8rem;
  font-weight: bold;
}

.cpo-notice-item-function-button-container{
  height: 50px;
  width: fit-content;
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  transition: .3s;
}
.cpo-notice-item-function-button-container{
  font-size: 1.5rem;
}
.cpo-notice-item-function-button-container:hover{
  background: #e4e8e9;
}

/* 
.cpo-fap-item-function-button-list{
  display: flex;
  align-items: center;
  justify-content: center;
  visibility:hidden;
  max-width: 0;
}
.cpo-fap-item-function-button-list.open{
  max-width: 150px;
  visibility: visible;
}
.cpo-fap-item-function-button-list>div{
  height: 50px;
  width: 50px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cpo-fap-item-function-button{
  width: 90%;
  height: 90%;
  border-radius: 100vh;
  background: #f6fbfc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.cpo-fap-item-function-button:active{
  opacity: 0.5;
}
.cpo-fap-item-function-button>div{
  width: 85%;
  height: 85%;
  border-radius: 100vh;
  background: var(--buttonBlueOuter);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  transition: background .3s;
}
.cpo-fap-item-function-button>div:hover{
  background: #b4eafa;
}
.cpo-fap-item-function-button.delete>div{
  background: var(--buttonRed);
  color: #333;
  transition:background .3s;
}
.cpo-fap-item-function-button.delete>div:hover{
  background: #ffa4a4;
}
.cpo-fap-item-function-button>div p{
  margin: 0;
  font-weight: bold;
} */

/* ************************************************************ */
/* NoticeDetail */
/* ************************************************************ */

.cpo-notice-detail-back-button{
  width: fit-content;
  margin-bottom: 15px;
  display: flex;align-items: center;
  color: #111;
  cursor: pointer;
  transition: .1s;
}
.cpo-notice-detail-back-button:active{
  opacity: 0.5;
}
.cpo-notice-detail-back-button p{
  margin: 0;
}
.cpo-notice-detail-header{
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--inputBorder3);
}
.cpo-notice-detail-header>div:last-child{
  height: 100%;
  display: flex;
  align-items: end;
}
.cpo-notice-detail-title{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.cpo-notice-detail-title h2{
  margin: 0;
  font-size: 1.7rem;
  font-weight: bold;
}
.cpo-notice-detail-title p{
  margin: 0;
  font-size: .9rem;
  color: #aaa;
  font-weight: 500;
}
.cpo-notice-detail-content-container{
  min-height: 700px;
  padding-top: 30px;
  transition: .3s;
  /* border: 1px solid #000; */
}
.cpo-notice-detail-content-container pre{
  margin: 0;
  margin-bottom: 15px;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-all;
  overflow: auto;
}



/* ************************************************************ */
/* NoticeEdit */
/* ************************************************************ */

.cpo-notice-container.edit{
  /* grid-row: 80px 1fr; */
  grid-template-rows: 80px 1fr;
  align-items: center;
  border: 1px solid var(--inputBorder3);
  border-radius: 8px;

}
.cpo-notice-detail-header.edit{
  padding: 20px;
  height: 100%;
}
.cpo-notice-detail-header.edit p{
  margin: 0;
}
.cpo-notice-edit-header-title{
  font-size: 1.2rem;
  font-weight: 600;
}
.cpo-notice-detail-header.edit .cpo-notice-append-button.edit{
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.cpo-notice-detail-back-button.edit{
  margin: 0;
}

.cpo-notice-edit-content-container{
  display: grid;
  grid-template-rows: 60px 1fr;
  padding: 20px;
  grid-template-columns: 120px 1fr;
  background: #f7f7f7;
}



.cpo-notice-edit-title-wrapper{
  display: flex;
  align-items: center;
  height: 100%;
  
}
.cpo-notice-detail-title.edit{
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--inputBorder3);
  height: 50px;
  padding: 0 10px;
  background: #fff;
}
.cpo-notice-append-button.disabled{
  opacity: 0.2;
  pointer-events: none;
}
.cpo-notice-detail-title input{
  all: unset;
  width: calc(100%);
  font-size: 1rem;
  transition: .3s;
}

.cpo-notice-detail-content-container.edit{
  margin-top: 20px;
  border-radius: 10px;
  border: 1.5px solid #ddd;
  padding: 10px;
  background: #fff;
}
.cpo-notice-detail-content-container textarea{
  all: unset;
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 1.5rem;
}
.cpo-notice-edit-content-container.edit>p{
  font-weight: 600;
  text-align: center;
  margin-right: 20px;
}
.cpo-notice-edit-content-container.edit>p:nth-child(3){
  margin-top: 30px;
  display: block;
  height: fit-content;
}



/* ************************************************************ */
/* NoticeDeleteModal */
/* ************************************************************ */

.notice-delete-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.notice-delete-modal-container{
  position: relative;
  min-width: 500px;
  min-height: 330px;
  height: fit-content;
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  /* overflow: hidden; */
  padding: 20px;
  z-index: 1000;
  display: grid;
  grid-template-rows: auto 1fr 50px;
}
.notice-delete-modal-header{
  width: 100%;
  margin-bottom: 30px;
}
.notice-delete-modal-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}
.notice-delete-modal-list-container{
  display: flex;
  align-items: center;
  justify-content: center;
}
.notice-delete-modal-msg{
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
}

.notice-delete-modal-container>div:last-child{
  display: flex;align-items: center;
  justify-content: space-between;
  
}
.notice-delete-modal-delete-button{
  width: 	100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
  transition: .13s;
  background: var(--cardBtnRed);
  cursor: pointer;
}
.notice-delete-modal-delete-button:active{
  opacity: 0.3;
}
.notice-delete-modal-delete-button p{
  margin: 0;
  font-weight: 600;
  font-size: 1rem;
  color: var(--textRed1);
}


/* ************************************************************ */
/* 1:1 Qna */
/* ************************************************************ */

.cpo-qna-container{
  display: grid;
  grid-template-rows: 180px auto;
  position: relative;
}
.cpo-qna-header{
  position: relative;
  transition: .3s;
  border-bottom: 2px solid #b6b6b6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cpo-qna-header>div:last-child{
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cpo-qna-list-container{
  min-height: 700px;
  /* padding: 10px; */
  transition: .3s;
  border-bottom: 1.5px solid #ddd;
}
.cpo-qna-item-container{
  display: grid;
  grid-template-columns: 100px calc(100% - 300px) 100px 100px;
  grid-template-rows: 70px auto auto;
  min-height: 70px;
  cursor: pointer;
  transition: .3s;
  position: relative;
  align-items: center;
  background: #fff;
  overflow: hidden;
  transition: .5s;
  max-width: 1100px;
  border-bottom: 1px solid var(--inputBorder3);
}
.cpo-qna-item-container:hover{
  /* background: #f2f4f6; */
}
.cpo-qna-item-container.clicked{
  /* background: #f2f4f6; */
}
.cpo-qna-item-container>div{
  height: 100%;
  display: flex;
  align-items: center;
}
.cpo-qna-item-container p{
  margin: 0;
}


.cpo-qna-item-comment-bool-container{
  width: 80px;
  display: flex;
  align-items: center;
  padding-left: 10px;

  /* justify-content: center; */
}
.cpo-qna-item-comment-bool{
  width: 100%;
  padding: 0 5px;
  height: 25px;
  border-radius: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cpo-qna-item-comment-bool.exist{
  background: #fff;
  border: 2px solid var(--emoovBlue);
}
.cpo-qna-item-comment-bool.none{
  background: #fff;
  border: 2px solid var(--textRed);

}
.cpo-qna-item-comment-bool p{
  margin: 0;
  font-size: .8rem;
  font-weight: bold;
}
.cpo-qna-item-comment-bool.exist p{
  color: var(--emoovBlue);
}
.cpo-qna-item-comment-bool.none p{
  color: var(--textRed);
}





.cpo-qna-item-category-container{
  padding-left: 10px;
}
.cpo-qna-item-category-container p{
  font-size: 1rem;
  color: var(--inputBorder2);

}
.cpo-qna-item-category-title-container{
  justify-content: start;
}
.cpo-qna-item-category-title-container p{
  font-size: 1rem;
  font-weight: bold;
}
.cpo-qna-item-container .cpo-qna-item-content-container{
  align-items: start;
  justify-content: start;
  transition: .5s;
  max-height: 90px;
  width: calc(100%);
  transition: .3s;
}
.cpo-qna-item-content-container.clicked{
  max-height: 800px;
  grid-row: 1 / 3;
  grid-column: 2;
}
.cpo-qna-item-content-container pre{
  margin: 0;
  max-height: 90px;
  margin-top: 11px;
  transition: 0.3s;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-line-clamp:1;
  padding: 15px;
  line-height: 1.6rem;
}
.cpo-qna-item-content-container.clicked pre{
  width: 100%;
  max-height: 800px;
  opacity: 1;
  transition: 0.3s;
  white-space: pre-wrap;
  word-break: break-all;
  overflow: auto;
  height: fit-content;
  /* background: #dce4ec; */
  /* border-radius: 15px; */
}
.cpo-qna-item-date-open-container{
  display: flex;
  align-items: center;
  justify-content: center;
}
.cpo-qna-item-date-open-container p{
  font-size: .8rem;
  margin: 0;
}



.cpo-qna-item-container>div.cpo-qna-item-comment-container{
  grid-column: -1/1;
  max-height: 0;
  transition: .3s;
  position: relative;
  padding-left: 100px;
  padding-right: 20px;
  display: block;
}
.cpo-qna-item-container>div.cpo-qna-item-comment-container.clicked{
  /* margin: 20px 0 30px 0; */
  padding: 20px 20px 20px 100px;
  max-height: 800px;
  background: #f7f7f7;
  cursor: default;
  border-top: 1px solid var(--inputBorder3);

}
.cpo-qna-questioner-info-container{
  display: flex;
  font-size: .8rem;
  cursor: text;
  margin-bottom: 15px;
  cursor: default;
}
.cpo-qna-questioner-info-container>p:first-child{
  position: relative;
  margin-right: 20px;
}
.cpo-qna-questioner-info-container>p:first-child::after{
  content: "";
  position: absolute;
  top: 0;
  right: -10px;
  display: block;
  width: 2px;
  height: 100%;
  background: #bbbbbb;
}
.cpo-qna-questioner-info-container>p:nth-child(2){
  margin-right: 40px;
  user-select: text;
  cursor: text;
}
.cpo-qna-questioner-info-container>p:nth-child(3){
  color: var(--inputBorder2);
  font-weight: 500;
}
.cpo-qna-item-comment-container.clicked .cpo-qna-item-comment{
  max-height: 500px;
}
.cpo-qna-item-comment{
  /* width: calc(100% - 250px); */
  width: 100%;
  height: fit-content;
  /* margin-left: 190px; */
  min-height: 70px;
  /* border: 1px solid #000; */
  border-radius: 8px;
  padding: 10px;
  font-size: .9rem;
  background: #fff;
  border: 1px solid var(--inputBorder3);
  position: relative;
}



.cpo-qna-item-comment-container .cpo-qna-item-comment textarea,
.cpo-qna-answer-container textarea{
  all: unset;
  display: inline-block;
  width: 100%;
  min-height: 100%;
  max-height: 450px;
  line-height: 1.5rem;
  cursor:text;
  transition: 0s;
  font-size: .9rem;
}
.cpo-qna-item-comment-send-button-wrapper{
  display: flex;
  justify-content: end;
  margin-top: 15px;
}
.cpo-qna-item-comment-send-button{
  width: 100px;
  height: 35px;
  background: #3d3d3d;
  border-radius: 8px;
  display: flex;
  align-items: center;justify-content: center;
  font-size: .9rem;
  color: #fff;
  cursor: pointer;
  transition: .13s;
}
.cpo-qna-item-comment-send-button.none{
  cursor: default;
  user-select: none;
  background: #f7f7f7;
}
.cpo-qna-item-comment-send-button p{
  margin-right: 5px;
}

.cpo-qna-item-comment-container .cpo-qna-item-comment-send-button:active{
  opacity: 0.3;
}
.cpo-qna-item-comment-send-button svg{
}


.cpo-qna-answer-container{
  border-top: 1px solid #e1e1e1;
  padding-top: 15px;
}

.cpo-qna-item-comment-time-container{
  font-size: .8rem;
  user-select: text;
  cursor: text;
  display: flex;
  margin-bottom: 15px;

}
.cpo-qna-item-comment-time-container>p:first-child{
  position: relative;
  margin-right: 20px;
}
.cpo-qna-item-comment-time-container>p:nth-child(2){
  color: var(--inputBorder2);
  font-weight: 500;
}





.cpo-qna-item-comment-container *{
  max-height: 0;
  min-height: 0;
  transition: .3s, opacity 0s;
  opacity: 0;
}
.cpo-qna-item-comment-container.clicked *{
  max-height: none;
  /* min-height: 70px; */
  opacity: 1;
}


.cpo-qna-err-msg{
  font-size: .9rem;
  font-weight: 600;
  margin: 0;
  margin-right: 20px;
  color: var(--textRed1);
}




/* ************************************************************ */
/* BreakDown */
/* ************************************************************ */

.cpo-breakdown-service-container{
  width: 100%;
  /* max-width: 1500px; */
  height: fit-content;
  min-height: calc(100vh - 190px);
  /* border: 1px solid #000; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cpo-breakdown-service-inner{
  width: 100%;
  max-width: 1400px;
  min-width: 850px;
  height: 100%;
  position: relative;
}
.cpo-breakdown-container{
  display: grid;
  grid-template-rows: 120px auto;
  position: relative;
}
.cpo-breakdown-header{
  position: relative;
  transition: .3s;
}
.cpo-breakdown-tab-container{
  margin-top: 40px;
  width: 100%;
  height: 50px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  border-bottom: 2px solid var(--tabBorder);
}

.breakdown-tab-button{
  border-radius: 7px 7px 0 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
}
.breakdown-tab-bar{
  position: absolute;
  width: 11.11111%;
  height: 3px;
  background: var(--emoovBlue);
  top: 47.5px;
  transition: .3s;
  transition: left 0.3s cubic-bezier(0.175, 0.35, 0.5, 1.13);
  z-index: 2;
}
.breakdown-tab-button.selected p{
  color: var(--emoovBlue);
}
.breakdown-tab-button p{
  margin: 0;
  color: var(--oxfordBlue1);
  font-weight: bold;
}
.breakdown-tab-bar.tab0{
  left: 0;
}
.cpo-breakdown-list-container{
  min-height: 700px;
  padding: 10px;
  transition: .3s;
  /* border-bottom: 1.5px solid #ddd; */
}

.cpo-breakdown-item-container{
  display: grid;
  grid-template-columns: 100px 100px calc(100% - 300px) 100px;
  grid-template-rows: 90px auto auto;
  min-height: 90px;
  cursor: pointer;
  transition: .3s;
  position: relative;
  /* margin-bottom: 10px; */
  align-items: center;
  background: #fff;
  overflow: hidden;
  transition: .5s;
  border-bottom: 1px solid var(--inputBorder3);
}
.cpo-breakdown-item-container:hover{
  /* background: #f2f4f6; */
}
.cpo-breakdown-item-container.clicked{
  /* background: #f2f4f6; */
}
.cpo-breakdown-item-container>div{
  height: 100%;
  display: flex;
  align-items: center;
}
.cpo-breakdown-item-container p{
  margin: 0;
}
.cpo-breakdown-item-comment-bool-container{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;  justify-content: center;
}
.cpo-breakdown-item-comment-bool{
  width: 75px;
  height: 25px;
  border-radius: 25px;
  display: flex; align-items: center; justify-content: center;
  font-size: .9rem;
}
.cpo-breakdown-item-comment-bool.done{
  font-weight: 600;
  border: 2px solid var(--emoovBlue);
  color: var(--emoovBlue);
}
.cpo-breakdown-item-comment-bool.recept{
  font-weight: 600;
  border: 2px solid #ffa600;
  color: #ffa600;
}
.cpo-breakdown-item-comment-bool.none{
  font-weight: 600;
  border: 2px solid var(--textRed);
  color: var(--textRed);
}
.cpo-breakdown-item-container>div:nth-child(2){
  font-size: .9rem;
  display: flex;align-items: center;justify-content: center;
}

.cpo-breakdown-item-container .cpo-breakdown-item-recept-button{
  width: 75px;
  height: 80px;
  border-radius: 10px;
  padding: 1.5px;
  display: flex;
  align-items: center;justify-content: center;
  position: absolute;
  left: 13px;
  bottom: 25px;
  border: none;
  background: #1364b0;
  box-shadow: 0 1px 4px rgb(51, 51, 51, 0.3);
  overflow: hidden;
  transition: .1s;
}
.cpo-breakdown-item-recept-button p{
  font-size: .9rem;
  font-weight: bold;
  color: #fff;
}
.cpo-breakdown-item-recept-button:hover svg{
  transform: translateX(25px);
}
.cpo-breakdown-item-container .cpo-breakdown-item-recept-button:active{
  opacity: 0.3;
}
.cpo-breakdown-item-recept-button svg{
  width: 100%;
  height: 60%;
  color: #fff;
  transition: .3s;
}

.cpo-breakdown-item-container .cpo-breakdown-item-content-container{
  align-items: start;
  justify-content: start;
  transition: .5s;
  max-height: 90px;
  margin-left: 10px;
  width: calc(100% - 10px);
  transition: .3s;
}
.cpo-breakdown-item-content-container.clicked{
  max-height: 800px;
  grid-row: 1 / 3;
  grid-column: 3;

}
.cpo-breakdown-item-content-container pre{
  margin: 0;
  max-height: 90px;
  margin-top: 21px;
  transition: 0.3s;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-line-clamp:1;
  padding: 15px;
}
.cpo-breakdown-item-content-container.clicked pre{
  width: 100%;
  max-height: 800px;
  opacity: 1;
  transition: 0.3s;
  white-space: pre-wrap;
  word-break: break-all;
  overflow: auto;
  height: fit-content;
  /* background: #dce4ec; */
  border-radius: 15px;
}
.cpo-breakdown-item-date-open-container{
  display: flex;
  align-items: center;
  justify-content: center;
}
.cpo-breakdown-item-date-open-container p{
  font-size: .8rem;
  margin: 0;
}


.cpo-breakdown-customer-info-container{
  display: flex;
  font-size: .9rem;
  margin-bottom: 15px;
}
.cpo-breakdown-customer-info-container>p:first-child{
  position: relative;
  margin-right: 20px;
}
.cpo-breakdown-customer-info-container>p:first-child::after{
  content: "";
  position: absolute;
  top: 0;
  right: -10px;
  display: block;
  width: 2px;
  height: 100%;
  background: #bbbbbb;
}
.cpo-breakdown-customer-info-container>p:nth-child(3){
  margin-left: 5px;
  margin-right: 40px;
  user-select: text;
  cursor: text;
}
.cpo-breakdown-customer-info-container>p:nth-child(4){
  color: var(--inputBorder2);
  font-weight: 500;
}

.cpo-breakdown-answer-info-container{
  display: flex;
  font-size: .9rem;
  
  border-top: 1px solid var(--inputBorder3);
  padding-top: 15px;
}
.cpo-breakdown-answer-info-container>p:first-child{
  margin-right: 20px;
}
.cpo-breakdown-answer-info-container>p:nth-child(2){
  color: var(--inputBorder2);
  font-weight: 500;
}


.cpo-breakdown-item-container .cpo-breakdown-item-comment-container{
  grid-column: -1/1;
  max-height: 0;
  transition: .3s;
  position: relative;
  display: block;
  cursor: default;
}
.cpo-breakdown-item-comment-container.clicked{
  /* margin: 20px 0 30px 0; */
  border-top: 1px solid var(--inputBorder3);
  padding: 20px 20px 20px 100px;
  max-height: 800px;
  background: #f7f7f7;
}
.cpo-breakdown-item-comment{
  width: calc(100%);
  height: fit-content;
  /* margin-left: 100px; */
  min-height: 70px;
  border: 1px solid var(--inputBorder3);
  border-radius: 8px;
  padding: 10px;
  background: #fff;
  position: relative;
}
.cpo-breakdown-item-comment.reply{
  background: none;
  border: none;
  padding: 0;
  margin-top: 15px;
}
.cpo-breakdown-item-comment.none{
  background: #efefef;
  user-select: none;
}


.cpo-breakdown-item-comment-container .cpo-breakdown-item-comment textarea{
  all: unset;
  display: inline-block;
  width: 100%;
  min-height: 100%;
  max-height: 768px;
  line-height: 1.5rem;
  cursor:text;
  transition: 0s;
}


.cpo-breakdown-item-comment-send-button-wrapper{
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.cpo-breakdown-item-comment-send-button{
  width: 100px;
  height: 35px;
  background: #3d3d3d;
  border-radius: 8px;
  display: flex;
  align-items: center;justify-content: center;
  font-size: .9rem;
  color: #fff;
  transition: .13s;
  cursor: pointer;
}
.cpo-breakdown-item-comment-send-button.none{
  cursor: default;
  user-select: none;
  background: #f7f7f7;
}
.cpo-breakdown-item-comment-send-button p{
  margin-right: 5px;
}

.cpo-breakdown-item-comment-container .cpo-breakdown-item-comment-send-button:active{
  opacity: 0.3;
}

.cpo-breakdown-item-call-container{
  position: absolute;
  right: 490px;
  top: -10px;
  width: fit-content;
  display: flex;
  align-items: center;
  word-break: break-all;
  user-select: text;
  pointer-events:unset;
}
.cpo-breakdown-item-call-container svg{
  margin-right: 5px;
  color: #5cb300;
}
.cpo-breakdown-item-call-container p{
  font-size: .8rem;
  user-select: text;
  cursor: text;
  font-weight: 500;
}

.cpo-breakdown-item-post-time-container{
  position: absolute;
  right: 80px;
  top: -10px;
  font-size: .8rem;
  user-select: text;
  cursor: text;
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cpo-breakdown-item-comment-time-container{
  position: absolute;
  right: 80px;
  bottom: 5px;
  font-size: .8rem;
  user-select: text;
  cursor: text;
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cpo-breakdown-item-comment-time-container .cpo-breakdown-item-modifying-button{
  cursor: pointer;
  font-weight: 500;
  transition: .1s;
}
.cpo-breakdown-item-comment-time-container .cpo-breakdown-item-modifying-button:active{
  opacity: 0.2;
}
.cpo-breakdown-item-comment-container *{
  max-height: 0;
  min-height: 0;
  transition: .3s, opacity 0s;
  opacity: 0;
}
.cpo-breakdown-item-comment-container.clicked *{
  max-height: none;
  /* min-height: 70px; */
  opacity: 1;
}


.cpo-breakdown-empty-container{
  display: flex;
  align-items: center;
  justify-content: start;
  height: 100%;
  font-size: 1.5rem;
  font-weight: 600;
  color: #aaa;
}
.cpo-breakdown-empty-container>p{
  margin: 0;
}