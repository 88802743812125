.cpo-invitation-management-container{
  width: 100%;
  /* max-width: 1500px; */
  height: fit-content;
  min-height: max(calc(100vh - 190px), 900px) ;
  /* border: 1px solid #000; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cpo-invitation-management-inner{
  width: 100%;
  max-width: 1400px;
  min-width: 1400px;
  height: 100%;
  position: relative;
}


.cpo-invitation-management-content-container{
  width: 100%;

}
.cpo-invitation-management-content-container.grid{
  display: grid;
  grid-template-columns: 1fr 500px;
}

.cpo-invitation-management-content-header{
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cpo-invitation-management-content-search-container{
  display: flex;
  align-items: center;
}


.cpo-invitation-management-content-search-filter-button{
  width: fit-content;
  height: 45px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder3);
  color: var(--inputBorder2);
  font-weight: 500;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .13s;
}

.cpo-invitation-management-content-search-filter-button:active{
  opacity: 0.3;
}
.cpo-invitation-management-content-search-filter-button>svg{
  margin-left: 10px;
  font-size: 1.2rem;
}

.cpo-invitation-management-content-search-input-container{
  display: grid;
  grid-template-columns: 1fr 20px;
  column-gap: 5px;
  align-items: center;
  height: 30px;
  width: 300px;
  border-bottom: 2px solid var(--inputBorder2);
  position: relative;
  margin-left: 30px;
  padding: 0 5px 5px 5px;
  transition: .3s;
}
.cpo-invitation-management-content-search-input-container:has(input:focus){
  border-bottom: 2px solid var(--emoovBlue);
  
}
.cpo-invitation-management-content-search-input-container input{
  all: unset;
  width: 100%;
  height: 100%;
}
.cpo-invitation-management-content-search-input-container>svg{
  color: var(--inputBorder2);
  cursor: pointer;
  transition: .13s;
}
.cpo-invitation-management-content-search-input-container>svg:active{
  opacity: 0.3;
}

.cpo-invitation-management-content-function-button-container{
  display: flex;
  align-items: center;
}
.cpo-invitation-management-content-function-button{
  height: 45px;
  padding: 0 15px;
  display: flex;align-items: center;justify-content: center;
  border-radius: 8px;
  margin-left: 10px;
  cursor: pointer;
  transition: .13s;
}
.cpo-invitation-management-content-function-button:active{
  opacity: 0.3;
}
.cpo-invitation-management-content-function-button.enroll{
  background: var(--emoovBlue);
  color: #fff;
}
.cpo-invitation-management-content-function-button.delete{
  border: 1px solid var(--emoovBlue);
  color: var(--emoovBlue);
  position: relative;
}
.cpo-invitation-management-content-function-button.cancel{
  border: 1px solid var(--inputBorder3);
  color: var(--inputBorder2);
}


.cpo-invitation-management-content-item-list-container{
  width: 100%;
  min-height: 300px;
  border-top: 2px solid var(--inputBorder2);
}
.cpo-invitation-management-content-item-column{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 1.8fr 1fr 1.3fr 1fr 0.5fr;
  column-gap: 10px;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;

  font-size: .9rem;
  color: var(--inputBorder2);
}
.cpo-invitation-management-content-item-column.tab0{
  grid-template-columns: 1fr 1fr 1fr 1fr;

}
.cpo-invitation-management-content-item-column.tab1{
  grid-template-columns: 50px 1fr 1fr 1fr 1fr;
}






/* CpoChargerModelListItem */

.cpo-invitation-management-content-item{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  column-gap: 10px;
  font-size: .9rem;

}
.cpo-invitation-management-content-item.deleteMode{
  grid-template-columns: 50px 1fr 1fr 1fr 1fr;
}
.cpo-invitation-management-content-item.checked{
  background: var(--emoovLightBlue2);
}

.cpo-invitation-management-content-item p{
  margin: 0;
}

.cpo-invitation-management-content-item>div{
  display: flex;align-items: center;
}


.cpo-invitation-management-content-item-status{
  border: 2px solid;
  padding: 5px 10px;
  border-radius: 100vh;
  border-color: var(--textRed);
  color: var(--textRed);
  font-weight: 500;
}
.cpo-invitation-management-content-item-status.true{
  border-color: var(--emoovBlue);
  color: var(--emoovBlue);
}

.invitation-item-deleted{
  color: var(--inputBorder2);
  font-weight: 600;
}





/* CpoChargerModelEnrollModal */
.cpo-invitation-invitation-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-invitation-invitation-modal-container{
  position: relative;
  max-width: 1100px;
  width: fit-content;
  height: fit-content;
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  /* overflow: hidden; */
  padding: 30px 30px;
  z-index: 1000;
}
.cpo-invitation-invitation-modal-header{
  width: 100%;
  margin-bottom: 30px;
  display: flex; align-items: center;
}
.cpo-invitation-invitation-modal-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}
.cpo-invitation-invitation-modal-header p{
  margin: 0;
  color: var(--inputBorder2);
}
.cpo-invitation-invitation-modal-close-button{
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: .13s;
}
.cpo-payment-function-modal-close-button:active{
  opacity: 0.3;
}


.cpo-invitation-invitation-modal-content{
  width: 500px;
}
.cpo-invitation-invitation-modal-content>div{
  margin: 20px 0;
}
.cpo-invitation-invitation-modal-content-header{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}


.cpo-invitation-invitation-modal-content-wrapper{
  width: 100%;
  margin-bottom: 15px;
}
.cpo-invitation-invitation-modal-content-wrapper.marginTop{
  margin-top: 40px;
}


.cpo-invitation-invitation-modal-content-wrapper>p{
  font-weight: 500;
  margin: 0;
  margin-bottom: 15px;
}
.cpo-invitation-invitation-modal-content-wrapper>p.alertMsg{
  font-weight: 500;
  margin: 0;
  margin-left: 10px;
  margin-top: 5px;
  font-size: .9rem;
  color: var(--textRed);
}
.cpo-invitation-invitation-modal-content-wrapper>p.alertMsg.success{
  color: var(--emoovBlue);
}
.cpo-invitation-invitation-modal-content-input-container{
  width: 100%;
  height: 45px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder3);
  padding: 0 10px;
}

.cpo-invitation-invitation-modal-content-input-container input{ 
  all: unset;
  height: 100%;
  width: 100%;
}






.cpo-invitation-invitation-modal-button-container{
  display: flex;
  align-items: center;
  justify-content: end;
}
.cpo-invitation-invitation-modal-button{
  width: 100%;
  height: 45px;
  padding: 0 15px;
  border-radius: 8px;
  background: #3d3d3d;
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;justify-content: center;
  cursor: pointer;
  transition: .13s;
  padding: 0 100px;
}
.cpo-invitation-invitation-modal-button.none{
  opacity: 0.3;
  pointer-events: none;
}
.cpo-invitation-invitation-modal-button:active{
  opacity: 0.3;
}


.radio-button-container{
  display: flex;
  align-items: center;
}
.radio-button{
  box-shadow: var(--inputBorder2) 0 0 0 1px;
  /* border: 1px solid #000; */
  border-radius: 100vh;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.radio-button.clicked{
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px var(--emoovBlue);
  background: var(--emoovBlue);
}
.radio-button-container>p{
  margin: 0;  margin-left: 10px;
}