.main-page-container{
  display: grid;
  grid-template-columns: auto 1fr;
  height: calc(100vh - 50px);
  overflow-y: scroll;
  width: 100vw;
}
.main-nav-controller{
  position: fixed;
  top: 10px;
  left: -10px;
  font-size: 1.5rem;
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
  z-index: 10;
  transform: translateX(250px);
}
.main-nav-controller:hover{
  background: #fff;
  box-shadow: 0px 0px 5px #898989;
}
.main-nav-controller.off{
  transform: rotate(180deg);
  left: 10px;
}
.main-nav{
  position: absolute;
  height: calc(100vh - 50px);
  /* height: 100%; */
  width: 280px;
  display: grid;
  grid-template-rows: 100px 1fr;
  color: #000;
  background: var(--navBgGray);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  /* background: #fff; */
  transition: .3s;
  z-index: 5;
}
.main-nav.off{
  transform: translateX(-280px);
  width: 0;
}
.main-nav-back{
  height: calc(100vh - 50px);
  /* height: 100%; */
  width: 280px;
  display: grid;
  grid-template-rows: 100px 1fr;
  color: #000;
  background: var(--navBgGray);
  transition: .3s;
}
.main-nav-back.off{
  transform: translateX(-280px);
  width: 0;
}
.nav-logo-container{
  width: 100%;
  height: 100%;
}
.nav-logo{
  width: 100%;
  height: 100%;
  object-position: 50% 50%;
  object-fit:contain;
  padding: 0 20px;
}


.side-bar-title{
  margin-top: 40px;
  margin-bottom: 10px;
  padding: 0 16px;
}
.side-bar-title p{
  margin: 0;
  font-weight: 600;
  font-size: .9rem;
  color: var(--textGray1);
}
.side-bar-container{
  padding: 0 16px;
}
.nav-button-list-container{
  overflow-y: scroll;
  overflow-x: visible;
  position: relative;
  padding-top: 2px;
}
.nav-button-container{
  position: relative;
  cursor: pointer;
}
.nav-button-inner{
  width: 100%;
  height: 48px;
  margin-bottom: 8px;
  transition: .2s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  color: var(--textGray2);
}
.nav-button-inner:hover{
  background-color: var(--hoverGray);
  font-weight: 600;
  color: var(--emoovGray);
}
.nav-button-inner:active{
  /* background-color: var(--emoovBlue10); */
  background-color: #fefefe;
  transform:scale(95%);
}
.nav-button-inner.active{
  /* background-color: var(--emoovBlue10); */
  background-color: #fefefe;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  color: var(--emoovGray);
}
.nav-button{
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  cursor: pointer;
}

.nav-button p{
  margin: 0 10px;
  font-weight: 600;
  /* user-select: none; */
}
.nav-chevron{
  position: absolute;
  right: 8px;
  transition: .3s;
}
.nav-chevron.active{
  transform: rotate(180deg);
}
.nav-sub-container{
  width: 100%; 
}
.nav-sub-inner{
  height: 48px;
  transition: .3s;
  margin-bottom: 4px;
  padding-left: 20px;

}
.nav-sub-inner.none{
  transition: .3s;
  height: 0;
}
.nav-sub-button{
  width: 100%;
  height: 48px;
  border-radius: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  cursor: pointer;
  transition: .3s;
  color: var(--textGray2);
}
.nav-sub-button:hover{
  color: var(--emoovGray);
  background-color: var(--hoverGray);
}
.nav-sub-button:active{
  transform: scale(95%);
}
.nav-sub-button.on{
  color: var(--emoovGray);
  /* background-color: var(--hoverBlue); */
  background-color: #fefefe;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}
.nav-sub-button.none{
  height: 0;
  transition: .3s;
}
.nav-sub-dot{
  font-size: .7rem;
  margin: 0 10px;
}
.nav-sub-button p{
  margin: 0;
  user-select: none;
}
.main-view-container{
  width: calc(100vw - 280px);
  height: calc(100vh - 50px);
  /* overflow-x: hidden; */
}
.main-view-container.navOff{
  width: calc(100vw - 0px);
}
.main-view-container{
  /* background: #000; */
  display: grid;
  grid-template-rows: 150px 1fr 150px;
  position: relative;
}
.main-view-header{
  width: 100%;
  height: 100%;
  /* border-bottom: 3px solid var(--navBgGray); */
  box-shadow: rgba(0, 0, 0, 0.2) 0px -2px 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px;
}

.main-view-title-container h3{
  font-size: .9rem;
  position: absolute;
  top: 20px;
  color: var(--emoovBlue);
}
.main-view-title-container h2{
  font-size: 2rem;
}
.main-nav-user-info-container{
  position: absolute;
  top: 0;
  right: 30px;
  display: flex;
}
.main-nav-user-info-container>p{
  margin-left: 30px;
  position: relative;
  color: #6d6d6d;
  font-size: .9rem;
}
.main-nav-user-info-container>p>color{
  display: inline;
  color: var(--emoovBlue);
  font-weight: 600;
}
.main-nav-go-to-main-button{
  cursor: pointer;
  transition: .13s;
}
.main-nav-go-to-main-button:active{
  opacity: 0.3;
}
.main-nav-user-info-container>p:nth-child(2)::after{
  content: "";
  position: absolute;
  top: 0;
  right: -15px;
  display: block;
  width: 2px;
  height: 100%;
  background: #bbbbbb;
}
.main-nav-user-info-container>p:last-child{
  cursor: pointer;
  transition: .13s;
}
.main-nav-user-info-container>p:last-child:active{
  opacity: 0.3;
}
footer{
  width: 100%;
  height: 50px;
  position: fixed;
  bottom: 0;
  z-index: 20;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 6px;
  background: #fcfcfc ;
  padding: 0 50px;
  color: var(--textGray1);
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}
section{
  padding: 20px;
  height: fit-content;
  position: relative;
}


.first-enroll-guide{
  position: relative;
  background: #fff;
  margin-top: 10px;
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 0.9rem;
  /* color: #fff; */
  font-weight: 600;
  border: 3px solid var(--emoovBlue);
  line-height: 1.2rem;
}
.first-enroll-guide:after {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-bottom-color: var(--emoovBlue);
	border-top: 0;
	border-right: 0;
	margin-left: -100px;
	margin-top: -10px;
}
.first-enroll-guide color{
  display: inline;
  color: var(--textBlue2);
  font-weight: 700;
}

.main-view-more-info{
  position: absolute;
  top: 120px;
  color: var(--emoovBlue10);
  font-weight: 600;
  font-size: .85rem;
  display: flex;
  align-items: center;
}
.main-view-more-info>svg{
  margin-right: 5px;
}