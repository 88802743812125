.cpo-station-charging-fee-management-container{
  width: 100%;
  /* max-width: 1500px; */
  height: fit-content;
  min-height: calc(100vh - 190px);
  /* border: 1px solid #000; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
}
.cpo-station-charging-fee-management-inner{
  width: 100%;
  /* margin: 0 50px; */
  min-width: 900px;
  max-width: 1400px;
  height: 100%;
  position: relative;
}
.station-charging-fee-list{
  width: 100%;
  min-width: 850px;
  margin-top: 50px;
  /* height: 200px; */
  /* border: 1px solid #000; */
  
}
.station-charging-fee-list-column{
  border-top: 2px solid var(--inputBorder);
  border-bottom: 1px solid var(--inputBorder3);
  height: 50px;
  position: relative;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 135px 135px;
}
.station-charging-fee-list-column-item{
  height: 100%;
  width: 100%;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: start; 
}
.station-charging-fee-list-column p{
  margin: 0;
  font-size: .8rem;
  color: var(--inputBorder2);
  font-weight: 400;
}
.station-charging-fee-list-content{
  /* padding: 0 35px; */
  width: 100%;
  height: fit-content;
  min-height: 270px;
  max-height: 500px;
  /* background: #f2f9fa; */
  /* box-shadow: 1px 2px 5px rgba(4, 41, 55, 0.2); */
  overflow: scroll;
  scrollbar-width: none;
}

.station-charging-fee-list-item{
  width: 100%;
  height: 50px;
  background: #fff;
  border-bottom: 1px solid var(--inputBorder3);
  transition: .13s;
  padding: 0px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 135px 135px;
  position: relative;
}

.station-charging-fee-list-item>div{
  height: 100%;
  padding: 0 10px;
  display: flex;align-items: center;
}
.station-charging-fee-list-item>div p{
  user-select:text;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow:ellipsis;
  word-break: break-all;
  margin: 0;
  font-size: .9rem;
  font-weight: 400;
  color: #555;
}
.station-charging-fee-status-container>div{
  border: 2px solid var(--inputBorder);
  font-size: .9rem;
  color: var(--inputBorder);
  padding: 5px 8px;
  font-weight: 600;
  border-radius: 100vh;
}
.station-charging-fee-status-container>div.custom{
  border: 2px solid var(--emoovBlue);
  color: var(--emoovBlue);

}
.station-charging-fee-custom-button{
  width: fit-content;
  height: 35px;
  background: var(--emoovBlue);
  color: #fff;
  font-weight: 600;
  font-size: .9rem;
  border-radius: 8px;
  padding: 0 15px;
  display: flex;
  align-items: center;justify-content: center;
  cursor: pointer;
  transition: .13s;
}
.station-charging-fee-custom-button.fast{
  background: var(--oxfordBlue2);
}
.station-charging-fee-custom-button:active{
  opacity: 0.3;
}







/* ****************************************************** */
/* StationChargingFeeSettingModal */
/* ****************************************************** */
.station-charging-fee-setting-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.station-charging-fee-setting-modal-container{
  position: relative;
  width: 1100px;
  /* height: 330px; */
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  overflow: hidden;
  padding: 20px;
}
.station-charging-fee-setting-modal-header{
  width: 100%;
  margin-bottom: 30px;
}
.station-charging-fee-setting-modal-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}