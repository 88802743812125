.cpo-member-info-update-container{
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 190px);
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 70px; */
}

.cpo-member-info-update-grid-container{
  position: relative;
  min-width: 1100px;
  max-width: 1100px;
  height: fit-content;
  display: grid;
  grid-template-columns: 380px auto;
  /* column-gap: 100px; */
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 6px;
}

.cpo-member-info-update-front{
  display: flex;
  align-items: center;
  justify-content: center;
  background:var(--emoovBlue);
  overflow: hidden;
  /* background: var(--emoovBlue10); */
  /* background-image: linear-gradient(to right, var(--emoovBlue10) 0%, #79CBCA  11%, #77A1D3  100%); */
  /* background-size: 200% auto; */
  position: relative;
  border-radius: 10px 0 0 10px;
}
.member-info-update-background{
  width: fit-content;
  
  content: var(--fastEnroll);
}
.member-info-update-logo{
  width: 100%;
  top: 0%;
  position: absolute;
  content: var(--logo_tran4);
}
.member-info-update-back-wrapper{
  position: relative;
  padding: 0 50px;
}




.member-info-update-tab-container{
  margin-top: 100px;
  min-height: 600px;
  margin-bottom: 30px;
}
.member-info-update-tab-container.done{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 700px;
  margin: 0;
}
.member-info-update-tab-title{
  margin: 0;
  font-size: 1.2rem;
  font-weight: 700;
}


.member-info-update-input-row{
  width: 100%;
  min-width: 600px;
  display: grid;
  grid-template-columns: 130px 1fr auto;
  margin-top: 15px;
  margin-bottom: 40px;
  position: relative;
}
.member-info-update-input-title{
  margin: 0;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: .9rem;
  font-weight: 600;
}
.member-info-update-input-container{
  width: 100%;
  height: 55px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder);
  /* padding: 0 20px; */
  transition: .13s;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.member-info-update-input-container:has(input:focus){
  border: 1px solid var(--emoovBlue);
}
.member-info-update-input-container:has(input:read-only:focus){
  border: 1px solid var(--inputBorder);
}
.member-info-update-input-container.invalid{
  border: 1px solid rgb(255, 82, 82);
}
.member-info-update-input-container input{
  all: unset;
  width: calc(100% - 40px);
  padding: 0 20px;
  height: 100%;
  pointer-events: text;
  /* background: #000; */
}
.member-info-update-input-container input:read-only{
  background: var(--inputBorder3);
  color: var(--inputBorder2);
}

.member-info-update-input-container.password input{
  padding-right: 40px;
}
.member-info-update-input-container input::placeholder{
  color: var(--placeholderGray);
}
.member-info-update-input-container svg{
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  color: var(--textGray);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.member-info-update-input-container path{
  display: block;
  height: 24px;
}




.member-info-update-token-send-button{
  width: 110px;
  height: 55px;
  border-radius: 8px;
  background: var(--emoovBlue);
  color: #fff;
  margin-left: 20px;
  display: flex;
  align-items: center;justify-content: center;
  font-weight: 600;
  cursor: pointer;
  transition: .13s;
}
.member-info-update-token-send-button.none{
  opacity: 0.3;
  pointer-events: none;
}
.member-info-update-token-send-button:active{
  opacity: 0.3;
}
.member-info-update-validation-msg{
  margin: 0;
  position: absolute;
  bottom: -1.2rem;
  left: 150px;
  font-size: .8rem;
  color: var(--textGray);
}
.member-info-update-validation-msg.alert{
  color: rgb(255, 82, 82)
}
.member-info-update-validation-msg.able{
  color: var(--emoovBlue);
}

.member-info-update-go-to-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 280px;
  position: relative;
}
.member-info-update-go-to-container>p{
  position: absolute;
  right: 0px;
  top: -30px;
  margin: 0;
  color: var(--textRed1);
}
.member-info-update-go-to-button{
  width: 200px;
  height: 50px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .13s;
}
.member-info-update-go-to-button:active{
  opacity: 0.3;
}
.member-info-update-go-to-button p{
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
  color: #fff;
}
.member-info-update-go-to-button.back{
  background: #B5B5B5;
}
.member-info-update-go-to-button.request{
  background: var(--emoovBlue);
}
.member-info-update-go-to-button.disabled{
  opacity: 0.3;
  pointer-events: none;
}

.member-info-update-done-msg{
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 50px;
}
.member-info-update-done-button{
  width: fit-content;
  height: 55px;
  background: var(--emoovBlue);
  color: #fff;
  font-weight: 600;
  display: flex;
  align-items: center;justify-content: center;
  cursor: pointer;
  transition: .13s;
  border-radius: 8px;
  padding: 0 20px;
}
.member-info-update-done-button:active{
  opacity: 0.3;
}