.cpo-charging-event-management-page-container{
  width: 100%;
  /* max-width: 1500px; */
  height: fit-content;
  min-height: calc(100vh - 190px);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cpo-charging-event-management-container p{
  margin: 0;
}
.cpo-charging-event-management-inner{
  width: 100%;
  max-width: 1400px;
  height: 100%;
  position: relative;
  padding: 20px;

}
.cpo-charging-event-management-container{
  display: grid;
  position: relative;
}
.cpo-charging-event-management-header{
  width: 100%;
  height: 50px;
  /* border-bottom: 1px solid #efefef; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 20px;
}

.cpo-charging-event-management-title-container{
  display: flex;align-items: center;
}
.cpo-charging-event-management-title-container title{
  display: inline-block;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

.cpo-charging-event-management-content-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  row-gap: 20px;
  margin-bottom: 30px;
}





/*************************************************/
/* *ChargingEventCard */
/*************************************************/

.cpo-charging-event-management-event-item{
  width: 100%;
  height: 100%;
  padding: 15px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto;
  row-gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  position: relative;
}
.cpo-charging-event-management-event-item-delete-button{
  position: absolute;
  width: 1rem;
  top: 15px;
  right: 15px;
  cursor: pointer;
  transform: .13s;
}
.cpo-charging-event-management-event-item-delete-button:active{
  opacity: 0.3;
}
.cpo-charging-event-management-event-item-title{
  font-size: 1.2rem;
  font-weight: 600;
  padding-right: 1rem;
}
.cpo-charging-event-management-event-item-period{
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--inputBorder2);
}
.cpo-charging-event-management-event-item-rate-wrapper{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
.cpo-charging-event-management-event-item-rate{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
.cpo-charging-event-management-event-item-rate-category{
  grid-column: -1/1;
  font-weight: 500;
  color: var(--emoovBlue);
}
.cpo-charging-event-management-event-item-rate-info{
  display: flex;align-items: end;
}
.cpo-charging-event-management-event-item-rate-info>p{
  margin: 0;
}
.cpo-charging-event-management-event-item-rate-info>p:first-child{
  margin-right: 5px;
  font-weight: 500;
  color: var(--inputBorder2);
}
.cpo-charging-event-management-event-item-rate-info>p:last-child{
  font-weight: 500;
  font-size: 1.2rem;
}
.cpo-charging-event-management-event-item-function-wrapper{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  margin-top: 10px;
}
.cpo-charging-event-management-event-item-function-button{
  width: 100%;
  height: 35px;
  display: flex;align-items: center;justify-content: center;
  background: #f0f0f0;
  color: var(--inputBorder2);
  font-weight: 600;
  font-size: .9rem;
  border-radius: 5px;
  cursor: pointer;
  transition: .13s;
}
.cpo-charging-event-management-event-item-function-button:active{
  opacity: 0.3;
}



/*************************************************/
/* *CpoChargingEventStationListModal */
/*************************************************/


.cpo-charging-event-station-list-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}

.cpo-charging-event-station-list-modal-container{
  position: relative;
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  overflow: hidden;
  padding: 20px;
  width: 1100px;
}


.cpo-charging-event-station-list-modal-content-container{
  position: relative;
}

.cpo-charging-event-station-list.settingModal{
  margin-top: 50px;
  position: relative;
}
.cpo-charging-event-station-list{
  width: 100%;
  min-width: 850px;
  
}
.cpo-charging-event-station-list-column{
  border-top: 2px solid var(--inputBorder);
  border-bottom: 1px solid var(--inputBorder3);
  height: 50px;
  position: relative;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr;
}
.cpo-charging-event-station-list-column.checkList{
  grid-template-columns: 0.3fr 1fr 2fr 4fr;

}
.cpo-charging-event-station-list-column-item{
  height: 100%;
  width: 100%;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: start; 
}

.cpo-charging-event-station-list-column-item.checkbox{
  position: absolute;
  top: -50px;
  left: 20px;
  width: fit-content;
  cursor: pointer;
  transition: .13s;
}
.cpo-charging-event-station-list-column-item.checkedInfo{
  position: absolute;
  top: -50px;
  left: 120px;
  width: fit-content;
}
.cpo-charging-event-station-list-column-item.checkedInfo>p{
  font-size: .9rem;
  color: var(--emoovBlue);
  font-weight: 600;
}
.cpo-charging-event-station-list-column-item.checkedInfo>p:nth-child(2){
  display: inline-block;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 100vh;
  background: var(--emoovBlue);
  color: #fff;
  font-weight: 600;
  margin-left: 5px;
}
.cpo-charging-event-station-list-column-item.checkedInfo>p:nth-child(3){
  color: var(--inputBorder2);
  margin-left: 10px;
  cursor: pointer;
  transition: .13s;
}
.cpo-charging-event-station-list-column-item.checkedInfo>p:nth-child(3):active{
  opacity: 0.3;
}

.cpo-charging-event-station-list-column-item.checkbox:active{
  opacity: 0.3;
}
.cpo-charging-event-station-list-column-item.checkbox p{
  color: var(--inputBorder2);
  font-weight: 500;
  font-size: .9rem;
  margin-left: 5px;
}

.cpo-charging-event-station-list-column p{
  margin: 0;
  font-size: .8rem;
  color: var(--inputBorder2);
  font-weight: 400;
}
.cpo-charging-event-station-list-content{
  width: 100%;
  height: fit-content;
  min-height: 270px;
  max-height: 500px;
  overflow: scroll;
  scrollbar-width: none;
}


.cpo-charging-event-station-list-content-item.clicked{
  background: var(--itemActiveBlue);
}
.cpo-charging-event-station-list-content-item{
  width: 100%;
  height: 50px;
  background: #fff;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0px 20px;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr;
  position: relative;
}
.cpo-charging-event-station-list-content-item.checkList{
  grid-template-columns: 0.3fr 1fr 2fr 4fr;
}
.cpo-charging-event-station-list-content-item>div.handle{
  position: absolute;
  right: 0;
  top: 0;
}
.cpo-charging-event-station-list-content-item>div{
  height: 100%;
  padding: 0 10px;
  display: flex;align-items: center;
}
.cpo-charging-event-station-list-content-item>div p{
  user-select:text;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow:ellipsis;
  word-break: break-all;
  margin: 0;
  font-size: .9rem;
  font-weight: 400;
  color: #555;
}

.cpo-charging-event-station-list-content-item>div:nth-child(1) label{
  display: flex;
  align-items: center;
  justify-content: center;
}


.cpo-charging-event-station-list-content-item-function-button-container{
  height: 40px;
  width: fit-content;
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: .3s;
}
.cpo-charging-event-station-list-content-item-function-button-container>svg{
  cursor: pointer;
}
.cpo-charging-event-station-list-content-item-function-button-container{
  font-size: 1.5rem;
}
.cpo-charging-event-station-list-content-item-function-button-container:hover{
  /* background: #e4e8e9; */
}
.cpo-charging-event-station-list-content-item-function-button-container:has(.cpo-station-management-list-content-item-function-button-list.open){
  box-shadow: 0px 0px 4px 0px #00000040;
  
}
.cpo-charging-event-station-list-content-item-function-button-list{
  display: flex;
  align-items: center;
  justify-content: center;
  visibility:hidden;
  max-width: 0;
  background: #fff;
  border-radius: 8px;
}
.cpo-charging-event-station-list-content-item-function-button-list.open{
  max-width: 150px;
  visibility: visible;
}
.cpo-charging-event-station-list-content-item-function-button-list>div:first-child::after, .cpo-charging-event-station-list-content-item-function-button-list>div:nth-child(2)::after{
  content: "";
  display: block;
  position: absolute;
  right: 0px;
  width: 1.2px;
  height: 50%;
  background: #E2E2E2;
}
.cpo-charging-event-station-list-content-item-function-button-list>div{
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.cpo-charging-event-station-list-content-item-function-button{
  width: max-content;
  height: 85%;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* transition: .1s; */
}
.cpo-charging-event-station-list-content-item-function-button:active{
  opacity: 0.5;
}
.cpo-charging-event-station-list-content-item-function-button>div{
  width: max-content;
  
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6E6E6E;
  transition: background .3s;
  font-size: 1.1rem;
  border: 1.5px solid var(--emoovBlue);
  padding: 10px;
  border-radius: 8px;
  margin: 0 10px;
  color: var(--emoovBlue);
  font-weight: 600;
}




/*************************************************/
/* *Charging Fee Setting Modal */
/*************************************************/

.cpo-charging-fee-setting-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-charging-fee-setting-modal-container{
  position: relative;
  width: 1100px;
  height: fit-content;
  border-radius: 8px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  /* overflow: hidden; */
  margin: 0 50px;
  padding: 30px;
}
.cpo-charging-fee-setting-modal-header{
  width: 100%;
  margin-bottom: 10px;
  display: flex;align-items: center;
  padding-bottom: 0;
}
.cpo-charging-fee-setting-modal-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}
.cpo-charging-fee-setting-modal-header>p{
  margin: 0;
}

.cpo-charging-fee-setting-modal-search-container{
  display: flex;
  align-items: center;
  margin: 30px 0;
  position: relative;
}

.cpo-charging-fee-setting-modal-search-filter-button{
  width: fit-content;
  height: 45px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder3);
  color: var(--inputBorder2);
  font-weight: 500;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .13s;
  margin-right: 30px;
}

.cpo-charging-fee-setting-modal-search-filter-button:active{
  opacity: 0.3;
}
.cpo-charging-fee-setting-modal-search-filter-button>svg{
  margin-left: 10px;
  font-size: 1.2rem;
}

.cpo-charging-fee-setting-modal-search-input-container{
  display: grid;
  grid-template-columns: 1fr 20px;
  column-gap: 5px;
  align-items: center;
  height: 45px;
  width: 500px;
  border-bottom: 2px solid var(--inputBorder2);
  position: relative;
  /* margin-left: 30px; */
  padding: 0 5px 5px 5px;
  transition: .3s;
}
.cpo-charging-fee-setting-modal-search-input-container:has(input:focus){
  border-bottom: 2px solid var(--emoovBlue);
  
}
.cpo-charging-fee-setting-modal-search-input-container input{
  all: unset;
  width: 100%;
  height: 100%;
}
.cpo-charging-fee-setting-modal-search-input-container>svg{
  color: var(--inputBorder2);
  cursor: pointer;
  transition: .13s;
}
.cpo-charging-fee-setting-modal-search-input-container>svg:active{
  opacity: 0.3;
}










.cpo-charging-fee-setting-modal-content-container{
}

.cpo-charging-fee-setting-modal-table{
  position: relative;
  width: 100%;
  
  background: #F7F7F7;
  padding: 0 30px;
  padding-top: 60px;
  border-top: 1px solid #D2D2D2;
  border-bottom: 1px solid #D2D2D2;
}
.cpo-charging-fee-setting-modal-table title{
  display: inline-block;
  /* font-size: 1.1rem; */
  font-weight: 600;
  margin-bottom: 10px;
  /* margin-top: 10px; */
}



.cpo-charging-fee-setting-modal-table-column{
  position: absolute;
  width: 100%;
  height: 60px;
  left: 0px;
  top: 0;
  padding: 0 50px;
  display: grid;

  grid-template-columns: 1.1fr 5fr 5fr;

  align-items: center;
  border-bottom: 1px solid #000;
  color: var(--inputBorder2);
}

.cpo-charging-fee-setting-modal-table-column-item{
  display: flex;
  align-items: center;
}
.cpo-charging-fee-setting-modal-table-column-item p{
  margin: 0;
  font-size: .9rem;
}


.cpo-charging-fee-setting-modal-table-column-item:first-child>p{
  margin: 0; margin-left: 5px;
}

.cpo-charging-fee-setting-modal-item-list{
  width: 100%;
  height: 500px;
  padding: 0;
  overflow-y: scroll;
  scrollbar-width: none;
}


.cpo-charging-fee-setting-modal-period-selection-container{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
}
.cpo-charging-fee-setting-modal-period-selection-container>div{
  width: 100%;
}
.cpo-charging-fee-setting-modal-period-selection-container>div>p{
  color: var(--emoovBlue);
  font-weight: 600;
}
.cpo-charging-fee-setting-modal-period-input-wrapper{
  width: 100%;
  height: 45px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  column-gap: 15px;
}
.cpo-charging-fee-setting-modal-period-input-container{
  border-radius: 8px;
  border: 1px solid var(--inputBorder3);
  width: 100%;
  height: 100%;
  padding: 0 10px;
}
.cpo-charging-fee-setting-modal-period-input-container input{
  all: unset;
  width: 100%;
  height: 100%;
}
.cpo-charging-fee-setting-modal-period-button{
  width: 100%;
  height: 100%;
  background: var(--emoovBlue);
  border-radius: 8px;
  display: flex;align-items: center;justify-content: center;
  color: #fff;
  cursor: pointer;
  transition: .13s;
}
.cpo-charging-fee-setting-modal-period-button:active{
  opacity: 0.3;
}
.cpo-charging-fee-setting-modal-save-button-container{
  width: 100%;
  height: 45px;
  margin: 30px 0;
  display: flex;align-items: center;justify-content: end;
}
.cpo-charging-fee-setting-modal-save-button{
  display: flex;align-items: center;justify-content: center;
  background: var(--buttonBlack);
  color: #fff;
  font-weight: 500;
  padding: 0 20px;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  transition: .13s;
}
.cpo-charging-fee-setting-modal-save-button:active{
  opacity: 0.3;
}
.cpo-charging-fee-setting-modal-save-button.none{
  pointer-events: none;
  opacity: 0.3;
}

.cpo-charging-fee-setting-modal-alert{
  position: absolute;
  top: 20px;right: 20px;
  width: 250px;
  height: 40px;
  border-radius: 8px;
  background: var(--emoovBlue1);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-left: 10px solid #1dceff;
}
.cpo-charging-fee-setting-modal-alert p{
  margin: 0;
  font-weight: 600;
  font-size: 0.9em;
  color: #1dceff;
}