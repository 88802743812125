
.sys-chas-profile-management-container{
  width: 100%;
  /* max-width: 1500px; */
  height: fit-content;
  min-height: max(calc(100vh - 190px), 900px) ;
  /* border: 1px solid #000; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sys-chas-profile-management-inner{
  width: 100%;
  max-width: 1400px;
  min-width: 1400px;
  height: 100%;
  position: relative;

}

.sys-chas-profile-management-content-container{
  width: 100%;
}